html {
  --ag-foreground-color: $black-100;
  --ag-data-color: $black-100;
  --ag-selected-row-background-color: transparent;
}

.theme-light .ui-icon.ui-theme--primary {
  color: $blue-mid;
}

html > body {
  margin: 0;
  font-size: 14px;
}

.theme-light .ui-option__control > input:hover ~ .ui-icon,
.theme-light .ui-option__control > input:focus ~ .ui-icon {
  color: $blue-mid;
}

.tox-tinymce-aux {
  z-index: 2200 !important;
}

div.row {
  margin: 10px 0 !important;
}

div[slot="main-content"] .collapsible-body {
  padding: 14px;
}

.input-field {
  margin-bottom: 0px !important;
}

div.col.check-box {
  margin-top: 22px;
  max-height: 35px;
}

.dropdown-content {
  z-index: 999 !important;
  &.select-dropdown {
    z-index: 1003 !important;
  }
}

.ui-badge {
  background-color: $blue-mid !important;
  color: $black-0 !important;
  box-shadow: none !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ribbon-header-text {
  max-width: calc(100% - 200px);
}

li.disabled {
  background-color: #eee;
  cursor: not-allowed !important;
}

td > i.material-icons {
  vertical-align: middle;
}

.condensed {
  .navbar__btn__logo {
    img {
      &.not-condensed {
        display: none;
      }
      &.condensed {
        display: block;
      }
    }
  }
  .navbar__menu ul .navbar__menu__item {
    > a {
      width: fit-content;
      .navbar__menu__item__content__title {
        width: fit-content;
        height: 21px;
        ui-icon {
          min-width: 18px;
        }
      }
    }
  }
}

.navbar__btn__logo {
  img {
    &.not-condensed {
      animation-name: menu-logo;
      animation-duration: 0.25s;
      //animation-delay: 5s;
    }
  }
}

@keyframes menu-logo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbar__btn__logo {
  //height: 70px;

  img {
    height: 38px;
    &.condensed {
      display: none;
      width: 48px;
      height: 50px;
    }
    &.not-condensed {
      display: block;
      width: 196.053px;
      height: 50px;
      //margin-left: -35px;
    }
  }
}

.navbar,
ui-viewport aside.ui-viewport__leftpart {
  box-shadow: none;
}

.ui-viewport__body {
  background-color: $blue-darkest;
}
.ui-viewport-center {
  // background: white;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  //border-radius: $radius;
  border-radius: 16px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ui-viewport__floating-container > .ui-drop > .ui-drop__body {
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  padding: 5px 10px;
  z-index: 2000;

  .ui-menu {
    .ui-menu__group {
      padding: 0;
      margin: 0;
      border-bottom: solid $gray-lighter 1px;
      &:last-child {
        border-bottom: none;
      }
      &::after {
        border: none;
      }
      .ui-menu__group__label {
        color: $black;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        padding: 10px 5px;
        padding-bottom: 15px;
      }
    }
    .ui-menu__group .ui-menu__group__container > .ui-menu__item,
    .ui-menu__item {
      margin: 5px 0;
      .ui-menu__item__link {
        padding: 5px;
        &:hover {
          background-color: $purple-lightest;
          .ui-menu__item__icon {
            color: $blue-mid;
          }
        }
        .ui-menu__item__icon {
          padding-right: 10px;
          color: $gray-light;
        }
        .ui-menu__item__label {
          padding: 0;
        }
        &[data-active="true"] {
          box-shadow: 4px 0px 0px -1px var(--menu-active-border-color, #1e2a72) inset;
          padding-left: 0.5rem;
        }
      }
    }
  }
}

ui-tab-router-view {
  overflow: hidden;

  #router-tabs-list {
    box-shadow: none !important;
    min-height: $ui-trv-tab-height;
    &.ui-tab-router-view-empty {
      background-color: $white;
      border-top-right-radius: $radius;
    }
    li {
      &:not(:first-child) {
        margin: 0;
        padding-left: 1rem;
      }
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding: 0 1rem;
      height: $ui-trv-tab-height;
      font-size: 14px;
      font-weight: 400;
      gap: 10px;

      .digi-cross::before {
        content: "\e907";
      }

      &.first {
        // Temporay rule, see ticket #10026
        // display: none;
        // EO rule
        // min-width: 100px !important;

        color: $purple-dark;
        border: none;
        ui-icon {
          font-size: 24px;
        }

        div {
          width: 100% !important;
          text-align: center;
        }
      }
    }

    router-view {
      position: relative;
    }
  }
  router-view {
    background-color: $white;
    > ui-page {
      background-color: $white;
    }
  }
}

/* --- Region input style --- */

.theme-light {
  a {
    text-decoration: none;
  }

  // .row {
  //   margin-bottom: 0 !important;
  // }
  ui-panel {
    // .row {
    //   margin-top: 10px !important;
    //   margin-bottom: 10px !important;
    // }
    &.horizontal-margin {
      > .ui-panel__body {
        padding: 0;
        width: 70%;
        margin: 0 auto;
        overflow: visible;
        .row > .col {
          > ui-checkbox {
            height: 38px;
            display: flex;
            align-items: center;
            > label {
              font-size: 14px;
            }
          }
          /*> ui-button {
            position: absolute;
            bottom: 10px;
          }*/
        }
        > div > div > .ui-field > ui-multi-value-input {
          width: 100%;
          .ui-multi-value-input__container > ui-field-wrapper {
            width: 100%;
          }
        }
        ui-field-wrapper {
          flex: 1 1 1rem;
        }
        ui-date-picker {
          flex: 1 1 1rem;
          > ui-field-wrapper {
            width: 100%;
          }
        }
      }

      .l12 {
        > ui-field {
          > label {
            & ~ .ui-field__wrapper {
              width: 100%;
            }
          }
        }
        .big-label {
          > ui-field {
            > label {
              width: 50%;
              & ~ .ui-field__wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  ui-segmented-control.segmented-control {
    height: 2.6rem;
    flex: 1 1 1rem;
    .selector {
      background-color: $blue-darkest;
    }
    ui-segmented-option.segmented-control__control > .segmented-control__input {
      display: none;
    }
  }
}

ui-select {
  > .select2 > .selection > .select2-selection > .select2-selection__arrow {
    margin-top: -1.5px;
    > b {
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      border: none;
      border-bottom: solid 2px $blue-darkest !important;
      border-right: solid 2px $blue-darkest !important;
    }
  }
}

.ui-input {
  border-radius: 6px;
}

.ui-field__label::after {
  padding: 0;
}

.ui-field__label {
  text-align: right;
  padding-right: 15px;
}

/* --- Region Chat --- */
ui-chat {
  .container_messages {
    avatar {
      margin: 0;
      height: auto;
    }
    margin-bottom: 0;
    .my_message {
      .message_info {
        flex-direction: row-reverse;
        .name {
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
          ~ p {
            display: flex;
            justify-content: flex-end;
            width: 40%;
          }
        }
      }
      .message_line {
        justify-content: flex-start;
        flex-direction: row-reverse;
        margin: 0;
        margin-right: 35px;
        .message {
          background-color: $purple-very-light;
          border-radius: 10px;
          border-top-right-radius: 0;
          > span {
            color: $blue-mid;
          }
        }
        .edit_button_group {
          flex-direction: row-reverse;
        }
      }
    }
    .container_textarea {
      .message {
        > span {
          color: $blue-mid;
        }
      }
    }
    .message_info {
      height: 30px;
      > .name {
        color: $blue-mid;
      }
      img {
        width: 35px;
        margin-top: 5px;
      }
    }
    .first_message_of_day {
      > hr {
        border: 1px black solid;
        opacity: 1;
      }
    }
    .name {
      width: 100%;
      padding-left: 10px;
      ~ p {
        width: 45%;
        font-size: 80%;
      }
    }
  }
}

ux-dialog-container .header-footer-fixed.chat-view {
  > .header-footer-fixed__footer {
    display: none;
  }
  > .header-footer-fixed__content > ui-chat {
    > .body_discussion > ul {
      margin: 0px;
    }
    > .footer_discussion {
      background-color: $purple-lighter;
    }
  }
}

ux-dialog-container {
  ui-panel {
    padding: 25px;
  }
}

ui-anchor-tab {
  border-bottom: $gray-very-light 1px solid !important;
  > div {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    &.isSelected {
      color: $black-100 !important;
      border-bottom: $blue-mid 1px solid !important;
      text-shadow: none !important;
    }
    &.isNotSelected {
      &:hover {
        color: $black-100 !important;
      }
    }
  }
}

ui-field[no-label].ui-field {
  > label {
    display: none;
  }
  grid-template-columns: auto;
}

.ui-btn__icon .ui-icon {
  font-size: 20px;
}

.value-unit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 75%;
  :first-child() {
    flex-grow: 2;
  }
  :last-child() {
    flex-grow: 1;
  }
}

.theme-light ui-field.label-top {
  display: block;
}

.ui-toolbar__left,
.ui-page__ribbon-content > div > div {
  display: flex;
  .page-title > .ui-button-detail__left__container {
    .ui-btn__wrapper {
      //border: solid 1px $purple-lighter !important;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      &:hover {
        background-color: $purple-lightest !important;
        border: solid 1px $purple-lightest !important;
      }
      .ui-btn__inner {
        box-shadow: none;
        .ui-btn {
          box-shadow: none;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
          &:active {
            background-color: transparent !important;
            box-shadow: none !important;
          }
          .ui-btn__icon {
            padding: 0;
            height: 39px;
            width: 39px;
            text-align: center;
            color: $blue-darkest;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              color: $blue-mid;
            }
          }
        }
      }
    }
  }
}

ui-page {
  > div > div > .ui-button-detail > .ui-toolbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .ui-toolbar__left {
      max-width: 85%;
    }
    .ui-toolbar__center {
      display: none;
    }
  }

  > .ui-page__body > .ui-section__content > .row {
    > .row > div > .ui-panel > .ui-panel__body {
      overflow: hidden;
    }
    .ui-panel > .ui-panel__body > .row > div {
      > .ui-panel > .ui-panel__body {
        overflow: hidden;
      }
    }
  }
}

/*
.page-title ~ ui-button {
  border-radius: 50px;
  box-shadow: none !important;
  border: solid 1px $purple-lightest !important;
  &:hover {
    background-color: $purple-lightest;
    .ui-btn {
      color: $blue-mid;
    }
  }
  > div {
    border-radius: 50px;
    > a {
      border-radius: 50px;
      box-shadow: none !important;
      background-color: transparent !important;
      color: $black-100;
    }
  }
}
*/
ui-date-picker {
  > ui-field-wrapper {
    div {
      > .ui-dropdown__link {
        padding: 0px 0.75rem;
      }
    }
  }
}

// #region step-timeline
.step-timeline {
  &__container {
    > span {
      &.actif,
      &.valid {
        > .step-timeline__el > .step-timeline__el__link {
          > .step-timeline__round {
            //background-color: $blue-mid !important;
          }
          > p {
            //color: $black-100 !important;
          }
        }
        > .step-timeline__line {
          background-color: $blue-mid !important;
        }
      }
      &.disabled {
        > .step-timeline__el > .step-timeline__el__link {
          > .step-timeline__round {
            //background-color: $gray-very-light !important;
          }
          > p {
            //color: $gray-very-light !important;
          }
        }
        > .step-timeline__line {
          background-color: $gray-very-light !important;
        }
      }
      > .step-timeline__line {
        background-color: $blue-mid !important;
      }
    }
  }
  &__container.horizontal {
    > span {
      > .step-timeline__line {
        width: 200px !important;
      }
    }
  }
}

step-timeline + div {
  margin-top: 50px;
}

// #endregion

ui-footer-page,
.metering-footer {
  background-color: $background-footer;
}
/*.theme-light ui-button.ui-btn__wrapper.blue-button {
  border-radius: 60px;
  background-color: $blue-mid;
  color: $white;
  height: 44px;
  &:hover {
    background-color: $merlin-blue-dark;
  }
  > div {
    padding: 0 20px;
  }
  .ui-btn__inner,
  .ui-btn__label {
    border-radius: inherit;
    color: inherit;
    background-color: inherit;
  }
  .ui-btn__inner > .ui-btn {
    &:hover,
    &:active {
      background-color: transparent !important;
      color: $black-0 !important;
      box-shadow: none !important;
    }
    .ui-btn__icon {
      color: $white;
    }
  }
}*/

/*ui-button.no-bg-button {
  border-radius: 60px;
  height: 44px;
  > div.ui-btn__inner {
    background-color: transparent;
    box-shadow: none !important;
    color: $black-50;
    &:hover {
      color: $black-100;
    }
    > .ui-btn {
      &:hover {
        background-color: transparent !important;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
}*/

/*ui-button.add-button {
  border-radius: 60px;
  height: 44px;
  background-color: $white;
  box-shadow: none;
  color: $blue-mid;
  border: 1px $purple-light solid;
  padding: 0px 10px;
  .ui-btn__inner {
    box-shadow: none !important;
    .ui-btn {
      &:active {
        background-color: transparent;
        box-shadow: none;
      }
      &:focus-within {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent !important;
      }
      &:hover {
        background-color: transparent !important;
      }
      > div {
        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }
  .ui-btn__inner,
  .ui-btn__label {
    border-radius: inherit;
    color: inherit;
    background-color: inherit;
    box-shadow: inherit;
  }
  &:hover {
    background-color: $blue-mid;
    color: $white;
  }
}*/

ui-page {
  > div > div {
    padding: 0px 0px 0px 10px;
    > ui-button {
      .ui-btn__inner {
        border-radius: 50px;
      }
    }
  }
}

div[required] {
  position: relative;
  &::after {
    content: "\2731";
    font-size: 8px;
    position: absolute;
    top: 2px;
  }
}

.theme-light .ui-calendar {
  border: none;
  .ui-calendar__selector {
    .ui-calendar__page .ui-calendar__page__row > .ui-calendar__page__head {
      color: $gray-light;
    }
    .range-footer {
      ui-checkbox {
        label {
          span {
            font-size: 0.9em;
            font-weight: 600;
          }
        }
      }
      .date-filter-reset {
        background-color: transparent;
        padding: 0;
        &:hover {
          color: $blue-mid;
        }
        .ui-btn {
          padding: 0;
        }
      }
    }
  }
  .ui-calendar__header {
    grid-template-areas: "prev title next";
    grid-template-columns: auto 1fr auto;
    text-align: center;
    > .ui-calendar__title {
      font-size: 20px;
      font-weight: 400;
    }
    > .ui-calendar__tool {
      color: $blue-darkest;
      border: none;
      &:hover {
        background-color: transparent;
        border: none;
      }
      &.next {
        transform: rotate(270deg);
      }
      &.prev {
        transform: rotate(-90deg);
      }
    }
  }
}

.theme-light .ui-calendar__page__cell.select-start,
.theme-light .ui-calendar__page__cell.select-end,
.theme-light .ui-calendar__page__cell:hover {
  background-color: $blue-mid !important;
  color: $black-0;
  box-shadow: none;
}

.theme-light .ui-calendar__page__cell.select-hilight:not(.select-end) {
  background-color: $purple-lighter;
  color: $black-100;
}

.theme-light .ui-calendar__page__cell.selected {
  background-color: $blue-mid !important;
}

ui-viewport .ui-viewport__body .ui-router-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  > ui-field {
    top: 123px;
    padding-right: 5px;
    display: grid;
    position: absolute;
    grid-template-columns: inherit;
    > .ui-field__label {
      position: initial;
      background: transparent;
      padding: 0;
    }
    > .ui-select {
      display: flex;
      align-items: center;
      padding-top: 0;
      &:hover {
        background-color: $purple-lightest;
        > .select2 > .selection > .select2-selection > .select2-selection__arrow > b {
          border-bottom: solid 2px $blue-mid !important;
          border-right: solid 2px $blue-mid !important;
        }
      }
      > span {
        display: flex;
        align-items: center;
        > span > span {
          display: flex;
          align-items: center;
          .select2-selection__rendered {
            padding-top: 7px;
          }
          .select2-selection__arrow {
            height: 100% !important;
          }
        }
        > .selection {
          width: inherit;
        }
      }
    }
  }
  .ui-field--required {
    > .ui-field__label {
      //grid-template-columns: auto 15px;
      height: 100%;
      align-items: center;
      > span:first-child {
        display: grid;
        height: 100%;
        align-items: center;
      }
    }
    > .ui-select > div {
      margin: auto 0;
      padding: 0;
      padding-right: 0.25rem;
      > svg {
        padding-top: 0px !important;
      }
    }
  }
  > router-view {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    > router-view {
      overflow: hidden;
    }
  }
}

ui-field > .ui-field__wrapper > .ui-select > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.select2-container--open > .select2-dropdown {
  border-radius: 8px;
  > .select2-search--dropdown {
    padding: 5px 0px !important;
    > .select2-search__field {
      border: solid 1px $gray-lighter !important;
    }
  }
  > .select2-results > .select2-results__options > .select2-results__option {
    /*border-bottom: solid 1px $gray-very-light;
    &:last-child {
      border-bottom: none;
    }*/
    > .project-index-select-item > div {
      height: 30px;
      &:first-child {
        height: inherit;
      }
      &:last-child {
        display: flex;
        gap: 10px;
        align-items: center;
        > avatar > img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  > .footer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  > ui-button {
    width: 100%;
  }
}

.select2-selection {
  .select2-search--inline {
    > .select2-search__field {
      margin-left: 0 !important;
      width: 200px !important;
      &::placeholder {
        font-size: 14px;
        color: #999999;
        font-family: "inter", sans-serif;
        white-space: nowrap;
        letter-spacing: 0.01em;
      }
    }
  }
  > button + ul + .select2-search--inline {
    display: none;
  }
}

.ux-add-files > ux-dialog-body {
  border-radius: 20px;
  > ui-button {
    display: flex;
    flex-direction: row-reverse;
  }
}

router-view > router-view {
  height: 100%;
}

.chat_history_button {
  padding: 0;
  > ui-button {
    border: $purple-lighter solid 1px;
    margin: 0;

    > .ui-btn__inner {
      box-shadow: none;
      border-radius: 0;
      color: $blue-darkest !important;
      &:hover {
        color: $blue-mid !important;
      }
      > .ui-btn {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
  > ui-button:first-child {
    border-right: none;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    > .ui-btn__inner {
      border-radius: inherit;
    }
  }
  > ui-button:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    > .ui-btn__inner {
      border-radius: inherit;
    }
  }
}

.ui-toolbar__right > div > ui-button {
  display: none;
}

.ui-toolbar__left > div > ui-button {
  .ui-btn {
    .ui-btn__icon {
      padding-right: 0px;
      > .ui-icon {
        font-size: 24px;
      }
    }
  }
}

.ui-input input {
  color: $black-100;
  font-weight: 400;
  font-family: "Inter";
}
body {
  font-family: "Inter";
  font-style: normal;
}
h1,
h2,
h3 {
  font-weight: 300;
}
h1,
h2,
h3,
h4 {
  font-weight: 400;
  color: $black-100;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}

.table-no-header {
  table > thead > tr {
    padding: 0;
    > th {
      padding: 0;
    }
  }
}
.table-no-data {
  table > tbody > tr {
    display: none;
  }
}

.ui-field-with-search-container {
  grid-template-areas: "label select icon";
  grid-template-columns: 35% auto 10% !important;
  > label {
    grid-area: label;
  }
  > ui-select,
  ui-input {
    grid-area: select;
  }
  > ui-input-addon {
    grid-area: icon;
  }
}

.navbar__menu__top,
.navbar__menu__bottom {
  padding: 0 15px;
}

.navbar__menu__bottom > ul {
  border-top: solid 1px $purple-dark;
  padding-top: 10px;
}

.theme-light {
  .navbar__btn {
    padding-left: 0;
    margin-bottom: 60px;
    margin-left: 15px;
    height: fit-content;
    .navbar__btn__logo > span > .condensed {
      margin: 0 auto;
    }
  }
  .navbar__menu {
    .navbar__menu__item {
      .isSelected {
        &:hover {
          background-color: $blue-mid !important;
        }
      }
    }
  }
}

.navbar__menu__item > a {
  //padding: 10px 15px;
  &:hover {
    background-color: transparent !important;
  }
  > .navbar__menu__item__content__title > ui-icon {
    font-size: 18px;
    margin: 0;
    margin-right: 15px;
  }
}

.navbar__menu__item__content__title > span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.theme-light .ui-tag--solid {
  background-color: $purple-very-light;
  border-radius: 60px;
  color: $blue-mid;
  box-shadow: none;
  > .ui-tag__label {
    padding: 8px 20px;
  }
}
.navbar__menu__bottom__header {
  > div {
    margin-bottom: 10px;
    ui-notification {
      display: block;
      height: 100%;
      ui-button {
        div > a {
          display: flex;
          .ui-badge.ui-badge__bottom {
            display: flex;
            height: 17px;
            min-width: 17px;
            align-items: center;
            justify-content: center;
            //line-height: unset;
            border-radius: 15px;
            font-size: 11px;
          }
          &:hover {
            .ui-btn__label,
            .ui-btn__icon > ui-icon {
              color: white;
            }
          }
        }
        &.has-label {
          width: 100%;
          .ui-badge.ui-badge__bottom {
            left: 20px;
            bottom: 0px;
          }
          .ui-btn__label {
            padding-left: 6px;
            color: $purple-light;
            font-family: "Inter", sans-serif;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.ui-progress-ring__circle {
  r: 18;
}

.preview-file {
  z-index: 2000;
}

.button-import {
  > .ui-btn__inner {
    background-color: $blue-mid !important;
    color: $black-0 !important;
    border-radius: $radius !important;
    font-size: 15px;
    padding: 5px 25px;
    &:hover {
      background-color: $merlin-blue-dark !important;
    }
    > .ui-btn {
      &:focus-within,
      &:hover,
      &:active {
        background-color: transparent !important;
      }
      > .ui-btn__icon {
        color: $black-0;
        padding: 0;
        padding-right: 10px;
      }
    }
  }
}

.button-cancel {
  margin-right: 30px;
  > .ui-btn__inner {
    background-color: transparent;
    border: none;
    box-shadow: none !important;
    color: $gray-mid !important;
    &:hover,
    &:focus-within {
      color: $black-100 !important;
      background-color: transparent !important;
    }
  }
}

.loader-center {
  text-align: center;
}

ag-cell-comment-box > footer > ui-button.ui-theme--secondary {
  .ui-btn__icon > .digi-cross {
    &::before {
      content: "\e98f";
    }
  }
}

ui-viewport .ui-viewport__body .ui-router-view {
  overflow: hidden;
}

ui-tree-panel {
  .ui-tree__container {
    .ui-tree__node {
      .ui-tree__expander {
        padding-right: 2px;
      }
      .ui-tree__spacer__lines {
        border-bottom: solid 1px $purple-mid;
        border-left: solid 1px $purple-mid;
        border-bottom-left-radius: 2px;
      }
    }
  }
}

.avatar {
  width: 24px;
  height: 24px;
}

.theme-light {
  .ui-list__item {
    background-color: $black-0;
    color: $black-100;
    &:hover {
      background-color: $purple-lightest;
      color: $blue-mid;
    }
  }
  .ui-list__item--selected {
    color: $black-0;
    background-color: $blue-mid;
  }
}

.ag-grid-view-box,
.view-parameter-box {
  .input-search > ui-input {
    width: auto !important;
  }
}

.input-search > ui-input {
  border-radius: $radius;
  padding: 6px 30px 6px 10px;
  border: 1px $purple-light solid !important;
  border-radius: 60px;
  height: 40px;
  width: fit-content !important;
  min-width: 230px;
  &:hover {
    border: solid 1px $purple-dark !important;
  }
  > .ui-input__addon {
    padding-right: 10px;
    color: $blue-darkest;
    > .ui-icon {
      font-size: 20px;
    }
  }
}

.z-index-level-3 {
  z-index: 1010 !important;
}

.z-index-level-4 {
  z-index: 1012 !important;
}

.no-padding {
  padding: 0px;
}

.user-signature {
  border: solid 1px $gray-lighter;
  min-height: 6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 10px;
  .signature {
    width: auto;
    max-width: 30rem;
    height: auto;
    max-height: 15rem;
  }
}

ui-field ui-toggle {
  margin-top: 0.8rem;
  > label {
    width: fit-content;
  }
}

.grid-header-bar {
  gap: 5px;
  .header-title {
    display: grid !important;
    grid-template-areas: "leftButton title button";
    grid-template-columns: auto auto;
    justify-content: start;

    .header-left-button {
      grid-area: leftButton;
      font-size: 15px !important;
      &.ui-btn__wrapper {
        width: 26px !important;
        height: 26px !important;
        > .ui-btn__inner {
          .ui-btn {
            .ui-btn__icon {
              height: 24px !important;
              width: 24px !important;
              > ui-icon {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    ui-button {
      margin-left: 0 !important;
      grid-area: button;
    }
  }
}

.ui-panel__header > .ui-header > .ui-header__title {
  font-size: 24px;
}

.ui-page > .ui-page__ribbon-content .page-title {
  font-size: 32px !important;
}

.grid-dialog-footer {
  grid-template-areas: "footer-left footer-right";
  > .grid-dialog-footer__left {
    grid-area: footer-left;
  }
  > .grid-dialog-footer__right {
    grid-area: footer-right;
    justify-content: flex-end;
  }
}

.theme-light body * {
  scrollbar-color: inherit;
  scrollbar-width: inherit;
}

@supports not ((-ms-overflow-style: none) or (-webkit-scrollbar: none)) {
  /* Standard Properties for browsers supporting scrollbar-width */
  .theme-light body * {
    scrollbar-color: inherit;
    scrollbar-width: inherit;
  }

  /* Only for Firefox scrollbar  */
  @-moz-document url-prefix() {
    .theme-light body * {
      scrollbar-color: var(--scrollbar-bg-hover, $scrollbar-bg-hover) transparent;
      scrollbar-width: thin;
    }
  }
}

.theme-light .ui-tree__label.ui-tree__selectable:hover {
  background-color: $purple-lightest;
  color: $blue-mid;
}

.theme-light .ui-tree__selected > .ui-tree__label {
  background-color: $purple-lightest !important;
  color: $blue-mid !important;
}

.theme-light a.link {
  margin: 10px 4px;
  color: $blue-mid;
  text-decoration: underline;
  cursor: pointer;
}

.flex-container {
  gap: 2rem;
  display: flex;

  .rounded {
    border: solid 1px #dddddd;
    border-radius: 24px;
    padding: 0.5rem;
  }

  .w5 {
    width: 5%;
  }
  .w10 {
    width: 10%;
  }
  .w15 {
    width: 15%;
  }
  .w20 {
    width: 20%;
  }
  .w25 {
    width: 25%;
  }
  .w30 {
    width: 30%;
  }
  .w35 {
    width: 35%;
  }
  .w40 {
    width: 40%;
  }
  .w45 {
    width: 45%;
  }
  .w50 {
    width: 50%;
  }
  .w55 {
    width: 55%;
  }
  .w60 {
    width: 60%;
  }
  .w65 {
    width: 65%;
  }
  .w70 {
    width: 70%;
  }
  .w75 {
    width: 75%;
  }
  .w80 {
    width: 80%;
  }
  .w85 {
    width: 85%;
  }
  .w90 {
    width: 90%;
  }
  .w95 {
    width: 95%;
  }
  .w100 {
    width: 100%;
  }
}
