import { ILoadingOverlayComp } from "ag-grid-community";
import { createOverrideContext } from "aurelia-binding";
import { Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { View, ViewSlot, ViewCompiler, ViewResources } from "aurelia-templating";
import { Box } from "digiwall-lib";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { IDataLineApiService } from "services/i-data-line-api-service";

export abstract class MeteringOverlayComp implements ILoadingOverlayComp {
  protected eGui: HTMLElement;
  protected view: View;
  protected viewSlot: ViewSlot;
  protected hasFilterApply: boolean;
  protected noDataLabel: string;
  protected i18n: I18N;
  protected priceOfferLinesGrid: PriceOfferLinesGrid;
  protected dataLineApi: IDataLineApiService;
  protected box: Box;
  protected hideAddItem: boolean = false;

  init(params) {
    this.priceOfferLinesGrid = params.priceOfferLinesGrid;
    let filterModel = params.api?.getFilterModel();
    this.hasFilterApply = filterModel != null && Object.keys(filterModel).length != 0;
    this.noDataLabel = params.noDataLabel;
    this.dataLineApi = params.meteringApi;
    if (params.hideAddItem != null) {
      this.hideAddItem = params.hideAddItem;
    }

    this.i18n = Container.instance.get(I18N);
    this.box = Container.instance.get(Box);
    this.setupGui();
  }

  public async setupGui() {

    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';

    let html = this.setupContentGui();

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }
  getGui(): HTMLElement {
    return this.eGui;
  }
  abstract setupContentGui(): string
}
