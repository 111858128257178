import { MonthlyExecutionOverviewDTO, TaskDTO, TaskOverviewDTO } from "module-tasks/model/module-task-model";

const
  day = 24 * 36e5,
  today = Math.floor(Date.now() / day) * day;

export class Mock {
  public static TaskDTOData: Array<TaskDTO> = [
    {
      id: 1,
      title: "Préparation du chantier",
      startDateTime: new Date(today - (30 * day)),
      endDateTime: new Date(today + (2 * day)),
      deadlineBackgroundColor: 'red',
      createdByUri: "https://merlinsoftwarestorage.blob.core.windows.net/local/users/47Tue Nov 21 2023-logo-picture.png?updatedTime=638361714701710755"
    } as TaskDTO,
    {
      id: 2,
      title: "Baliser la zone",
      startDateTime: new Date(today - (30 * day)),
      endDateTime: new Date(today - (20 * day)),
      deadlineBackgroundColor: 'red',
      parentTaskId: 1,
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 3,
      title: "Avertir la police",
      startDateTime: new Date(today - (15 * day)),
      // endDateTime: today - (12 * day) as any,
      deadlineBackgroundColor: 'red',
      parentTaskId: 1,
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 4,
      title: "Dégager la zone",
      startDateTime: new Date(today - (12 * day)),
      endDateTime: new Date(today + (2 * day)),
      deadlineBackgroundColor: 'orange',
      parentTaskId: 1,
      createdByUri: "https://merlinsoftwarestorage.blob.core.windows.net/local/users/47Tue Nov 21 2023-logo-picture.png?updatedTime=638361714701710755"
    } as TaskDTO,
    {
      id: 5,
      title: "Exécution",
      startDateTime: new Date(today - (3 * day)),
      endDateTime: new Date(today + (40 * day)),
      deadlineBackgroundColor: 'orange',
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 6,
      title: "Acheminement des matériaux et encore du texte pour être très long",
      startDateTime: new Date(today - (3 * day)),
      endDateTime: new Date(today + (5 * day)),
      parentTaskId: 5,
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 7,
      title: "Préparation des betons",
      startDateTime: new Date(today + (5 * day)),
      endDateTime: new Date(today + (12 * day)),
      parentTaskId: 5,
      createdByUri: "https://merlinsoftwarestorage.blob.core.windows.net/local/users/47Tue Nov 21 2023-logo-picture.png?updatedTime=638361714701710755"
    } as TaskDTO,
    {
      id: 8,
      title: "Coulées",
      startDateTime: new Date(today + (12 * day)),
      endDateTime: new Date(today + (40 * day)),
      parentTaskId: 5,
      createdByUri: "https://merlinsoftwarestorage.blob.core.windows.net/local/users/47Tue Nov 21 2023-logo-picture.png?updatedTime=638361714701710755"
    } as TaskDTO,
    {
      id: 9,
      title: "Coulées horizontales",
      startDateTime: new Date(today + (12 * day)),
      endDateTime: new Date(today + (25 * day)),
      parentTaskId: 8,
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 10,
      title: "Coulées verticales",
      startDateTime: new Date(today + (27 * day)),
      endDateTime: new Date(today + (40 * day)),
      parentTaskId: 8,
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 11,
      title: "Autre chantier",
      startDateTime: new Date(today + (42 * day)),
      endDateTime: new Date(today + (48 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 12,
      title: "Autre chantier",
      startDateTime: new Date(today + (50 * day)),
      endDateTime: new Date(today + (58 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 13,
      title: "Autre chantier",
      startDateTime: new Date(today + (58 * day)),
      endDateTime: new Date(today + (63 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 14,
      title: "Autre chantier 56",
      startDateTime: new Date(today + (60 * day)),
      endDateTime: new Date(today + (68 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 15,
      title: "Autre chantier",
      startDateTime: new Date(today + (68 * day)),
      endDateTime: new Date(today + (71 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 16,
      title: "Autre chantier",
      startDateTime: new Date(today + (71 * day)),
      endDateTime: new Date(today + (78 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 17,
      title: "Autre chantier",
      startDateTime: new Date(today + (80 * day)),
      endDateTime: new Date(today + (86 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 18,
      title: "Autre chantier",
      startDateTime: new Date(today + (86 * day)),
      endDateTime: new Date(today + (95 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 19,
      title: "Autre chantier",
      startDateTime: new Date(today + (95 * day)),
      endDateTime: new Date(today + (103 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 20,
      title: "Autre chantier",
      startDateTime: new Date(today + (103 * day)),
      endDateTime: new Date(today + (115 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 21,
      title: "Autre chantier",
      startDateTime: new Date(today + (115 * day)),
      endDateTime: new Date(today + (123 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 22,
      title: "Autre chantier",
      startDateTime: new Date(today + (120 * day)),
      endDateTime: new Date(today + (125 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 23,
      title: "Autre chantier",
      startDateTime: new Date(today + (130 * day)),
      endDateTime: new Date(today + (140 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
    {
      id: 24,
      title: "Autre chantier",
      startDateTime: new Date(today + (150 * day)),
      endDateTime: new Date(today + (158 * day)),
      createdByUri: "https://ui-avatars.com/api/?name=de Beco Charles-Thomas&length=3&size=32&background=random&color=ffffff&font-size=0.4"
    } as TaskDTO,
  ];

  public static TaskOverviewDTOData: Array<TaskOverviewDTO> = [
    {
      name: 'A faire',
      value: 23,
      color: "#777"
    },
    {
      name: 'En cours',
      value: 12,
      color: "#83cbeb"
    },
    {
      name: 'En retard',
      value: 5,
      color: "#FF0000"
    },
    {
      name: 'Terminées',
      value: 26,
      color: "#00b050"
    },
    {
      name: 'Annulées',
      value: 5,
      color: "#de91ff"
    }
  ];

  public static MonthlyExecutionOverviewDTOData1: MonthlyExecutionOverviewDTO = {
    outgoingsAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 44560.36],
      [new Date(Date.UTC(2023, 1, 1)), 24560.54],
      [new Date(Date.UTC(2023, 2, 1)), 64560.87]
    ],
    progressStatesInitialAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 24560],
      [new Date(Date.UTC(2023, 1, 1)), 34560],
      [new Date(Date.UTC(2023, 2, 1)), 24560]
    ],
    progressStatesPayOutAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 4456],
      [new Date(Date.UTC(2023, 1, 1)), 5456],
      [new Date(Date.UTC(2023, 2, 1)), 2456]
    ],
    billedAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 14000],
      [new Date(Date.UTC(2023, 1, 1)), 32000],
      [new Date(Date.UTC(2023, 2, 1)), 82000]
    ],
    realMargins: [
      [new Date(Date.UTC(2023, 0, 1)), 12.50],
      [new Date(Date.UTC(2023, 1, 1)), 54.89],
      [new Date(Date.UTC(2023, 2, 1)), 67.95]
    ],
    forecastMargin: 44.68
  }

  public static MonthlyExecutionOverviewDTOData2: MonthlyExecutionOverviewDTO = {
    outgoingsAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 44560],
      [new Date(Date.UTC(2023, 1, 1)), 24560],
      [new Date(Date.UTC(2023, 2, 1)), 64560],
      [new Date(Date.UTC(2023, 3, 1)), 44560],
      [new Date(Date.UTC(2023, 4, 1)), 24560],
      [new Date(Date.UTC(2023, 5, 1)), 64560],
      [new Date(Date.UTC(2023, 6, 1)), 44560],
      [new Date(Date.UTC(2023, 7, 1)), 24560],
      [new Date(Date.UTC(2023, 8, 1)), 64560],
      [new Date(Date.UTC(2023, 9, 1)), 44560],
      [new Date(Date.UTC(2023, 10, 1)), 24560],
      [new Date(Date.UTC(2023, 11, 1)), 64560],
    ],
    progressStatesInitialAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 24560],
      [new Date(Date.UTC(2023, 1, 1)), 34560],
      [new Date(Date.UTC(2023, 2, 1)), 24560],
      [new Date(Date.UTC(2023, 3, 1)), 24560],
      [new Date(Date.UTC(2023, 4, 1)), 34560],
      [new Date(Date.UTC(2023, 5, 1)), 24560],
      [new Date(Date.UTC(2023, 6, 1)), 24560],
      [new Date(Date.UTC(2023, 7, 1)), 34560],
      [new Date(Date.UTC(2023, 8, 1)), 24560],
      [new Date(Date.UTC(2023, 9, 1)), 24560],
      [new Date(Date.UTC(2023, 10, 1)), 34560],
      [new Date(Date.UTC(2023, 11, 1)), 24560],
    ],
    progressStatesPayOutAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 2456],
      [new Date(Date.UTC(2023, 1, 1)), 3456],
      [new Date(Date.UTC(2023, 2, 1)), 2456],
      [new Date(Date.UTC(2023, 3, 1)), 2456],
      [new Date(Date.UTC(2023, 4, 1)), 3456],
      [new Date(Date.UTC(2023, 5, 1)), 2456],
      [new Date(Date.UTC(2023, 6, 1)), 2456],
      [new Date(Date.UTC(2023, 7, 1)), 3456],
      [new Date(Date.UTC(2023, 8, 1)), 2456],
      [new Date(Date.UTC(2023, 9, 1)), 2456],
      [new Date(Date.UTC(2023, 10, 1)), 3456],
      [new Date(Date.UTC(2023, 11, 1)), 2456],
    ],
    billedAmounts: [
      [new Date(Date.UTC(2023, 0, 1)), 14000],
      [new Date(Date.UTC(2023, 1, 1)), 32000],
      [new Date(Date.UTC(2023, 2, 1)), 82000],
      [new Date(Date.UTC(2023, 3, 1)), 14000],
      [new Date(Date.UTC(2023, 4, 1)), 32000],
      [new Date(Date.UTC(2023, 5, 1)), 82000],
      [new Date(Date.UTC(2023, 6, 1)), 14000],
      [new Date(Date.UTC(2023, 7, 1)), 32000],
      [new Date(Date.UTC(2023, 8, 1)), 82000],
      [new Date(Date.UTC(2023, 9, 1)), 14000],
      [new Date(Date.UTC(2023, 10, 1)), 32000],
      [new Date(Date.UTC(2023, 11, 1)), 82000],
    ],
    realMargins: [
      [new Date(Date.UTC(2023, 0, 1)), 12.50],
      [new Date(Date.UTC(2023, 1, 1)), 54.89],
      [new Date(Date.UTC(2023, 2, 1)), 67.95],
      [new Date(Date.UTC(2023, 3, 1)), 12.50],
      [new Date(Date.UTC(2023, 4, 1)), 54.89],
      [new Date(Date.UTC(2023, 5, 1)), 67.95],
      [new Date(Date.UTC(2023, 6, 1)), 12.50],
      [new Date(Date.UTC(2023, 7, 1)), 54.89],
      [new Date(Date.UTC(2023, 8, 1)), 67.95],
      [new Date(Date.UTC(2023, 9, 1)), 12.50],
      [new Date(Date.UTC(2023, 10, 1)), 54.89],
      [new Date(Date.UTC(2023, 11, 1)), 67.95],
    ],
    forecastMargin: 44.68
  }

  public static MonthlyExecutionOverviewDTOData3: MonthlyExecutionOverviewDTO = {
    outgoingsAmounts: [
      [new Date(Date.UTC(2024, 0, 1)), 45560],
      [new Date(Date.UTC(2024, 1, 1)), 25560],
      [new Date(Date.UTC(2024, 2, 1)), 65560],
      [new Date(Date.UTC(2024, 3, 1)), 45560],
      [new Date(Date.UTC(2024, 4, 1)), 25560],
      [new Date(Date.UTC(2024, 5, 1)), 65560],
      [new Date(Date.UTC(2024, 6, 1)), 45560],
      [new Date(Date.UTC(2024, 7, 1)), 25560],
      [new Date(Date.UTC(2024, 8, 1)), 65560],
      [new Date(Date.UTC(2024, 9, 1)), 45560],
      [new Date(Date.UTC(2024, 10, 1)), 25560],
      [new Date(Date.UTC(2024, 11, 1)), 65560],
    ],
    progressStatesInitialAmounts: [
      [new Date(Date.UTC(2024, 0, 1)), 25560],
      [new Date(Date.UTC(2024, 1, 1)), 35560],
      [new Date(Date.UTC(2024, 2, 1)), 25560],
      [new Date(Date.UTC(2024, 3, 1)), 25560],
      [new Date(Date.UTC(2024, 4, 1)), 35560],
      [new Date(Date.UTC(2024, 5, 1)), 25560],
      [new Date(Date.UTC(2024, 6, 1)), 25560],
      [new Date(Date.UTC(2024, 7, 1)), 35560],
      [new Date(Date.UTC(2024, 8, 1)), 25560],
      [new Date(Date.UTC(2024, 9, 1)), 25560],
      [new Date(Date.UTC(2024, 10, 1)), 35560],
      [new Date(Date.UTC(2024, 11, 1)), 25560],
    ],
    progressStatesPayOutAmounts: [
      [new Date(Date.UTC(2024, 0, 1)), 3456],
      [new Date(Date.UTC(2024, 1, 1)), 4456],
      [new Date(Date.UTC(2024, 2, 1)), 3456],
      [new Date(Date.UTC(2024, 3, 1)), 3456],
      [new Date(Date.UTC(2024, 4, 1)), 4456],
      [new Date(Date.UTC(2024, 5, 1)), 3456],
      [new Date(Date.UTC(2024, 6, 1)), 3456],
      [new Date(Date.UTC(2024, 7, 1)), 4456],
      [new Date(Date.UTC(2024, 8, 1)), 3456],
      [new Date(Date.UTC(2024, 9, 1)), 3456],
      [new Date(Date.UTC(2024, 10, 1)), 4456],
      [new Date(Date.UTC(2024, 11, 1)), 3456],
    ],
    billedAmounts: [
      [new Date(Date.UTC(2024, 0, 1)), 15000],
      [new Date(Date.UTC(2024, 1, 1)), 33000],
      [new Date(Date.UTC(2024, 2, 1)), 83000],
      [new Date(Date.UTC(2024, 3, 1)), 15000],
      [new Date(Date.UTC(2024, 4, 1)), 33000],
      [new Date(Date.UTC(2024, 5, 1)), 83000],
      [new Date(Date.UTC(2024, 6, 1)), 15000],
      [new Date(Date.UTC(2024, 7, 1)), 33000],
      [new Date(Date.UTC(2024, 8, 1)), 83000],
      [new Date(Date.UTC(2024, 9, 1)), 15000],
      [new Date(Date.UTC(2024, 10, 1)), 33000],
      [new Date(Date.UTC(2024, 11, 1)), 83000],
    ],
    realMargins: [
      [new Date(Date.UTC(2024, 0, 1)), 13.50],
      [new Date(Date.UTC(2024, 1, 1)), 55.89],
      [new Date(Date.UTC(2024, 2, 1)), 68.95],
      [new Date(Date.UTC(2024, 3, 1)), 13.50],
      [new Date(Date.UTC(2024, 4, 1)), 55.89],
      [new Date(Date.UTC(2024, 5, 1)), 68.95],
      [new Date(Date.UTC(2024, 6, 1)), 13.50],
      [new Date(Date.UTC(2024, 7, 1)), 55.89],
      [new Date(Date.UTC(2024, 8, 1)), 68.95],
      [new Date(Date.UTC(2024, 9, 1)), 13.50],
      [new Date(Date.UTC(2024, 10, 1)), 55.89],
      [new Date(Date.UTC(2024, 11, 1)), 68.95],
    ],
    forecastMargin: 45.75
  }
}
