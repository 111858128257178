
import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType, Various, IMenuItems } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class InvoiceList extends ListViewModelBase<Merlin.Web.Model.SellingInvoice> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.SellingInvoice>(Constants.EntityTypeNames.SellingInvoice));
    this.appParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.service.gridDataSource.expands = ['invoiceStatus', 'client', 'project', 'payementStatus', 'defaultVATScheme'];
  }

  public invoiceTypeId: number;

  public ressourceName: string = Constants.EntityTypeNames.Project;
  public buttonLabel: string;
  public pathDetail: string;
  public placeHolderQuickFilter: string;
  private nbDecimalForPriceDisplay: number
  private appParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private projectId: number;
  private agGridViewModuleView: string


  public getDetailsUrl(_self, entity: Merlin.Web.Model.SellingInvoice) {
    if (entity?.id) {
      if (this.projectId != null) {
        this.goToDetail(entity.id)
      }
      else {
        return this.pathDetail + entity.id;
      }
    }
    return null;
  }

  override async activate(params: any) {
    await super.activate(params);
    if (params.projectId != null) {
      this.projectId = params.projectId;
    }
    if ((this.router.history as any).fragment.includes("/invoices/all")) {
      this.invoiceTypeId = Constants.SellingInvoiceTypeId.Invoice;
      this.buttonLabel = this.i18n.tr("sellinginvoice.addSellingInvoice");
      this.placeHolderQuickFilter = this.i18n.tr("sellinginvoice.searchInvoice");
      this.pathDetail = '/invoices/';
      this.agGridViewModuleView = "invoices";
    }
    else if ((this.router.history as any).fragment.includes("/credit-notes/all")) {
      this.invoiceTypeId = Constants.SellingInvoiceTypeId.CreditNote;
      this.buttonLabel = this.i18n.tr("sellinginvoice.addCreditNote");
      this.placeHolderQuickFilter = this.i18n.tr("sellinginvoice.searchCreditNote");
      this.pathDetail = '/credit-notes/';
      this.agGridViewModuleView = "credit-notes";
    }
    else if ((this.router.history as any).fragment.includes("/billing-vouchers/all")) {
      this.invoiceTypeId = Constants.SellingInvoiceTypeId.BillingVoucher;
      this.buttonLabel = this.i18n.tr("sellinginvoice.addBillingVoucher");
      this.placeHolderQuickFilter = this.i18n.tr("sellinginvoice.searchBillingVoucher");
      this.pathDetail = '/billing-vouchers/';
      this.agGridViewModuleView = "billing-vouchers";
    }
    else if ((this.router.history as any).fragment.includes("/buying-invoices/all")) {
      this.invoiceTypeId = Constants.SellingInvoiceTypeId.BuyingInvoice;
      this.buttonLabel = this.i18n.tr("sellinginvoice.addBuyingInvoice");
      this.placeHolderQuickFilter = this.i18n.tr("sellinginvoice.searchBuyingInvoice");
      this.pathDetail = '/buying-invoices/';
      this.agGridViewModuleView = "buying-invoices";
    }
    else if ((this.router.history as any).fragment.includes("/buying-credit-notes/all")) {
      this.invoiceTypeId = Constants.SellingInvoiceTypeId.BuyingCreditNote;
      this.buttonLabel = this.i18n.tr("sellinginvoice.addBuyingCreditNote");
      this.placeHolderQuickFilter = this.i18n.tr("sellinginvoice.searchBuyingCreditNote");
      this.pathDetail = '/buying-credit-notes/';
      this.agGridViewModuleView = "buying-credit-notes";
    }
    this.nbDecimalForPriceDisplay = (await this.appParameterService.getEntityById(1)).nbDecimalForPriceDisplay;
    if (this.projectId != null) {
      this.service.gridDataSource.queryParameters = { invoiceTypeId: this.invoiceTypeId, projectId: this.projectId };
    }
    else {
      this.service.gridDataSource.queryParameters = { invoiceTypeId: this.invoiceTypeId };
    }
  }

  goToDetail(id: number | null) {
    if (id == null) {
      id = -100;
    }
    if (this.projectId == null) {
      this.navigateTo(this.pathDetail + id);
    }
    let url = "";
    if (this.invoiceTypeId == Constants.SellingInvoiceTypeId.Invoice) {
      url = this.router.generate("invoices-detail", { invoiceId: id });
    }
    else if (this.invoiceTypeId == Constants.SellingInvoiceTypeId.CreditNote) {
      url = this.router.generate("credit-notes-detail", { creditNoteId: id });
    }
    else {
      url = this.router.generate("billing-vouchers-detail", { billingVoucherId: id });
    }
    if (this.router?.currentInstruction?.queryString?.length > 0) {
      url += '?' + this.router.currentInstruction.queryString;
    }
    this.router.navigate(url);

  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    let projectsService: ServiceBase<Merlin.Web.Model.Project> = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    let vatService: ServiceBase<Merlin.Web.Model.VATLevel> = new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel);
    let contractService: ServiceBase<Merlin.Web.Model.ThirdPartyContract> = new ServiceBase<Merlin.Web.Model.ThirdPartyContract>(Constants.EntityTypeNames.ThirdPartyContract);

    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceStatusId"),
        field: "invoiceStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.SellingInvoiceStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.SellingInvoiceStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.clientId"),
        field: "client.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.projectId"),
        field: "project.projectName",
        type: FieldType.OneToMany,
        filterParams: {
          service: projectsService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: projectsService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceDate"),
        field: "invoiceDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.imposedInvoiceDate"),
        field: "imposedInvoiceDate",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceNumber"),
        field: "invoiceNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.noteMultiline"),
        field: "noteMultiline",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.lastEmailSendingDateTime"),
        field: "lastEmailSendingDateTime",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.defaultVATSchemeId"),
        field: "defaultVATScheme.code",
        type: FieldType.OneToMany,
        filterParams: {
          service: vatService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: vatService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.totalExclVAT"),
        field: "totalExclVAT",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.totalVAT"),
        field: "totalVAT",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.total"),
        field: "total",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
    ];
    if (this.invoiceTypeId != Constants.SellingInvoiceTypeId.BillingVoucher) {
      defs.push(...[
        {
          headerName: this.i18n.tr("sellinginvoice.payementStatusId"),
          field: "payementStatus.denomination._translation",
          type: FieldType.Enumeration,
          filterParams: {
            category: Constants.EnumerationTypes.PaymentStatus,
          },
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            category: Constants.EnumerationTypes.PaymentStatus,
          },
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        },
        {
          headerName: this.i18n.tr("sellinginvoice.dueDate"),
          field: "dueDate",
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            dateFormat: Various.DateFormat
          },
          type: FieldType.Date,
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        },
        {
          headerName: this.i18n.tr("sellinginvoice.payementCommunication"),
          field: "paymentCommunication",
          type: FieldType.String,
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        },
      ]);
    }
    if (this.invoiceTypeId == Constants.SellingInvoiceTypeId.BuyingCreditNote || this.invoiceTypeId == Constants.SellingInvoiceTypeId.BuyingInvoice) {
      defs.push(...[
        {
          headerName: this.i18n.tr("sellinginvoice.validationStatusId"),
          field: "validationStatus.denomination._translation",
          type: FieldType.Enumeration,
          filterParams: {
            category: Constants.EnumerationTypes.InvoiceValidationStatus,
          },
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            category: Constants.EnumerationTypes.InvoiceValidationStatus,
          },
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        },
        {
          headerName: this.i18n.tr("sellinginvoice.activityId"),
          field: "activity.denomination._translation",
          type: FieldType.Enumeration,
          filterParams: {
            category: Constants.EnumerationTypes.ActivityCode,
          },
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            category: Constants.EnumerationTypes.ActivityCode,
          },
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        },
        {
          headerName: this.i18n.tr("sellinginvoice.contractId"),
          field: "contract.numContract",
          type: FieldType.OneToMany,
          filterParams: {
            service: contractService
          },
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            service: contractService
          },
          showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
        }
      ]);
    }
    defs.push(...new GridHelperMerlin().baseEntityColDef(this));
    return defs;
  }

  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = (params as any).pathDetail + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => {
          return params.data.invoiceStatusId != Constants.SellingInvoiceStatusId.Draft;
        }
      }];
  }
  @computedFrom("selectedEntities.length")
  get canDelete(): boolean {
    return this.selectedEntities.length > 0 && this.selectedEntities.filter(x => x.invoiceStatusId != Constants.SellingInvoiceStatusId.Draft).length == 0
  }
}

