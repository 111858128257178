import { bindable, autoinject, customElement } from 'aurelia-framework';
import * as Highcharts from 'highcharts';
import { ChartConfig } from '../chart-config';
import { I18N } from 'aurelia-i18n';
import { ChartPieSerie } from '../chart-mappers';

@autoinject
@customElement('chart-pie')
export class ChartPie {

  @bindable()
  private data: Array<ChartPieSerie>;
  @bindable()
  private title: string;
  @bindable
  private donut = false;
  @bindable
  private legend = false;
  @bindable
  private seriesName: string = "";
  @bindable
  private valueSuffix: string = "";

  protected container: HTMLDivElement;
  private options: Highcharts.Options;
  private chart: Highcharts.Chart;
  private chartConfig: ChartConfig;
  constructor(private i18n: I18N) {
    this.chartConfig = new ChartConfig(this.i18n);
  }

  attached() {
    this.setChartOptions();
    this.chart = Highcharts.chart(this.options);
  }

  private setChartOptions(): void {
    // Set I18N
    Highcharts.setOptions({ lang: this.chartConfig.getLangOptions() });

    this.options = {
      credits: { enabled: false },
      chart: Object.assign({
        type: 'pie',
        renderTo: this.container
      }, this.chartConfig.getChartOptions()),
      title: {
        text: this.i18n.tr(this.title),
        style: {
          color: "#777"
        }
      },
      accessibility: {
        point: {
          valueSuffix: this.valueSuffix
        }
      },
      legend: { enabled: this.legend },
      plotOptions: {
        series: {
          allowPointSelect: true,
          showInLegend: true,
          cursor: 'pointer',
          dataLabels: [
            {
              distance: this.container.clientWidth > 400 ? 15 : 8,
              format: (this.container.clientWidth > 400 ? '{point.y}' + this.valueSuffix + ' {point.name}' : '{point.y}' + this.valueSuffix),
            } as any,
          ],
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}' + this.valueSuffix + '</b>'
      },
      series: [
        {
          type: 'pie',
          name: this.i18n.tr(this.seriesName),
          innerSize: this.donut ? '75%' : null,
          data: this.data,
          borderRadius: 8
        }
      ]
    }
  }
}
