import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel, KeyboardShortcut } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { MeteringTotalPrices, IPriceOfferLineGridService, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine, RequestMovePriceOfferLine, BulkUpdateOperation } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { JSONPatchOperation } from './json-patch-operation';
import { RefToDisplay } from '../constants';
import { InvoiceDTO, RealizationDateDTO, TaskStatusDTO } from 'projects/realization/realization-summary';

@autoinject
export class RealizationApiService implements IPriceOfferLineGridService {

  protected callback: () => void;

  public projectId: number;

  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private logger: CustomLogger) { }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.RealizationController.Children.format(this.projectId.toString(), lineId.toString(), displayHidden.toString(), refToDisplay.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.RealizationController.Filter.format(this.projectId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let requestUri = Constants.Application.RealizationController.Fetch.format(this.projectId.toString(), refToDisplay.toString());
    let result = await this.httpClient.post(requestUri, JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.RealizationController.PatchLine.format(this.projectId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }

  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.RealizationController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  async duplicate(projectId: number, lineId: number): Promise<Array<number>> {
    let requestUri = Constants.Application.RealizationController.Duplicate.format(this.projectId.toString(), lineId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  async addAdditionalWork(projectId: number, lineId: number, additionalWorkId: number): Promise<Array<number>> {
    let requestUri = Constants.Application.RealizationController.AddAdditionalWork.format(this.projectId.toString(), lineId.toString(), additionalWorkId.toString());
    let response = await this.httpClient.get(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }

  async create(projectId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: projectId,
      action: action
    };
    let requestUri = Constants.Application.RealizationController.Create.format(this.projectId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }

  async delete(projectId: number, lineIds: Array<number>): Promise<Array<number>> {
    let result = await this.canDelete(lineIds);
    if (result) {
      let msg = lineIds.length == 1 ? this.i18n.tr('menu.delete_confirmation') : this.i18n.tr('menu.delete_confirmation_plural', { count: lineIds.length });
      let ids = [];

      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.ok", { ns: "common" }),
        title: this.i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.cancel", { ns: "common" }),
        title: this.i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            let requestUri = Constants.Application.RealizationController.Delete.format(this.projectId.toString());
            let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
            if (response.ok) {
              let temp = await response.json();
              ids.push(...temp);
            } else {
              if (response.status == 400) {
                this.logger.LogError(this.i18n.tr('realizationmetering.notCanDeleteLink'), null, null, true);
              }
            }
          }
        });
      return ids;
    } else {
      this.box.showWarning(this.i18n.tr('versionmetering.notCanDelete'), this.i18n.tr('general.warningTitle'));
      return [];
    }
  }


  private async canDelete(lineIds: Array<number>): Promise<boolean> {
    let requestUri = Constants.Application.RealizationController.CanDelete.format(this.projectId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(lineIds));
    if (response.ok) {
      return await response.json();
    }
  }

  async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    let requestUri = Constants.Application.RealizationController.CreateFromItems.format(this.projectId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    return null;
  }

  async getMeteringTotalPrices(projectId: number): Promise<MeteringTotalPrices> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.GetMeteringTotalPrices.format(this.projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async canCreateClientAdditionalWork(): Promise<boolean> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.CanCreateClientAdditionalWork.format(this.projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }
  async getLastChapterId(versionId: number): Promise<number> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.GetLastChapterId.format(versionId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async getRealizationDateDTO(projectId: number): Promise<RealizationDateDTO> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.GetRealizationDateDTO.format(projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async getTasksStatus(projectId: number): Promise<TaskStatusDTO> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.GetTasksStatus.format(projectId.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }

  async getInvoicesList(projectId: number, inComingSelected: boolean, outGoingSelected: boolean): Promise<Array<InvoiceDTO>> {
    let query = await this.httpClient.get(Constants.Application.RealizationController.GetInvoicesList.format(projectId.toString(), inComingSelected.toString(), outGoingSelected.toString()));
    if (query.ok) {
      let result = await query.json();
      return result;
    }
  }
}
