import { EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import { autoinject, bindable, BindingEngine, customElement } from "aurelia-framework";
import { Merlin } from "generated";
import * as Constants from '../constants';

@customElement('progress-statement-column-order')
@autoinject
export class ProgressStatementColumnOrder {
  @bindable columns: Merlin.Web.Model.ApplicationParameterProgressStatementDocument[];
  @bindable type: number | string;

  private progressStatementQuantityTypeService: EnumerationTypeService;
  private service: ServiceBase<Merlin.Web.Model.ApplicationParameterProgressStatementDocument>;

  private totalNumberOfColumn: number;

  constructor(private bindingEngine: BindingEngine) {
    this.progressStatementQuantityTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ProgressStatementQuantityType)
    this.service = new ServiceBase<Merlin.Web.Model.ApplicationParameterProgressStatementDocument>(Constants.EntityTypeNames.ApplicationParameterProgressStatementDocument);
  }

  async bind() {
    //this.reindexOrders();
    this.totalNumberOfColumn = await this.progressStatementQuantityTypeService.getCount();
    this.columns = this.columns.sort((n1, n2) => {
      if (n1.order > n2.order) {
        return 1;
      }

      if (n1.order < n2.order) {
        return -1;
      }

      return 0;
    });
    this.columns.forEach(column => {
      this.initObserver(column);
    });
    this.setSelectableEnum();
  }

  async addColumn() {
    let lastColumn = this.columns.last();

    var newColumn: Merlin.Web.Model.ApplicationParameterProgressStatementDocument = await this.service.createEntity({
      order: lastColumn != null ? lastColumn.order + 1 : 1,
      progressStatementType: this.type,
      applicationParameterId: 1
    });

    this.columns.push(newColumn);
    this.initObserver(newColumn);
  }

  async removeColumn(column: Merlin.Web.Model.ApplicationParameterProgressStatementDocument) {
    let index = this.columns.findIndex(x => x.order == column.order);
    let deleted = await this.service.deleteEntities([column], true, null, column.progressStatementQuantityTypeId == null);
    if (deleted) {
      this.columns.splice(index, 1);
      this.reindexOrders();
      this.setSelectableEnum();
      await this.service.saveEntities(this.columns.filter(x => !x.entityAspect.entityState.isAdded()), true);
    }
  }

  private moveLeft(column: Merlin.Web.Model.ApplicationParameterProgressStatementDocument) {
    let index = this.columns.findIndex(x => x.order == column.order);
    if (index > 0) {
      this.moveItem(index, index - 1);
    }
  }
  private moveRight(column: Merlin.Web.Model.ApplicationParameterProgressStatementDocument) {
    let index = this.columns.findIndex(x => x.order == column.order);
    if (index + 1 < this.columns.length) {
      this.moveItem(index, index + 1);
    }
  }

  private moveItem(fromIndex: number, toIndex: number) {
    const item = this.columns.splice(fromIndex, 1)[0];
    this.columns.splice(toIndex, 0, item);
    this.reindexOrders();
  }

  reindexOrders() {
    this.columns.forEach((item, index) => {
      item.order = index + 1;
    });
  }

  private initObserver(column: Merlin.Web.Model.ApplicationParameterProgressStatementDocument) {
    this.bindingEngine.propertyObserver(column, 'progressStatementQuantityTypeId').subscribe((newVal, oldVal) => {
      this.setSelectableEnum();
    });
  }

  private setSelectableEnum() {
    let idsToRemove = this.columns.filter(x => x.progressStatementQuantityTypeId != null).map(x => x.progressStatementQuantityTypeId);
    this.progressStatementQuantityTypeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ProgressStatementQuantityType, idsToRemove: idsToRemove };
  }

  private isColumnMandatory(type: number) {
    return type == Constants.ProgressStatementQuantityTypeId.UnitPrice ||
      type == Constants.ProgressStatementQuantityTypeId.Ref ||
      type == Constants.ProgressStatementQuantityTypeId.Description ||
      type == Constants.ProgressStatementQuantityTypeId.Unit ||
      type == Constants.ProgressStatementQuantityTypeId.TotalSellingPrice
  }
} 
