@import "../../css/scss/variables";

.quote-summary ui-panel.horizontal-margin {
  > .ui-panel__header {
    display: none;
  }

  > .ui-panel__body {
    width: 100%;

    .flex-container {
      chart-gantt .chart-gantt,
      chart-pie .chart-pie {
        min-height: 600px;
      }
      .task-box {
        max-height: 617px;
        overflow: scroll;
        .title-task-status {
          display: flex;
          gap: 5px;
          padding: 10px;
          span {
            font-size: 14px;
            font-weight: 500;
            &:last-child {
              display: flex;
              width: 25px;
              height: 25px;
              border-radius: 100%;
              align-items: center;
              justify-content: center;
              color: $white;
            }
          }
          span.late {
            background-color: $red-warning;
          }
          span.urgent {
            background-color: $merlin-orange-dark;
          }
          span.soon {
            background-color: #ffc000;
          }
        }
        .task-box-elem {
          border: 1px solid $gray-lighter;
          border-radius: 10px;
          margin-bottom: 5px;
          .task-cell {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: inherit;
            > div {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              &:first-child {
                padding-left: 10px;
              }
              ui-button {
                border-radius: $radius;
                border: solid 1px $gray-very-light !important;
                width: 24px;
                height: 24px;
                .ui-btn__icon {
                  padding: 0;
                }
              }
              > div > .task-link-name {
                font-size: 12px;
                line-height: 15px;
                color: $gray-mid;
              }
              > div > .task-title,
              > .task-info {
                display: flex;
                line-height: initial;
              }
              > div > .task-title {
                padding-bottom: 15px;
                ui-icon {
                  color: $gray-light;
                  font-size: 15px;
                }
              }
              &:first-child {
                justify-content: center;
              }
              &:not(:first-child) {
                width: 100%;
                overflow: hidden;
                margin: 10px;
                margin-right: 15px;
              }
              > div > .task-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                display: flex;
                gap: 5px;
                justify-content: space-between;
                align-items: center;

                > span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              > .task-info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .avatar {
                  max-height: 24px;
                  max-width: 24px;
                  margin-right: 2.5px;
                }
                > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  > div {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    > .task-date,
                    .task-type,
                    .task-status {
                      overflow: hidden;
                      .ui-tag__label {
                        overflow: inherit;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                  .task-date {
                    padding: 0 2.5px;
                    .ui-tag {
                      display: flex;
                      align-items: center;
                      flex-direction: row-reverse;
                      padding: 4px 15px;
                      margin: 0;
                      .ui-tag__label {
                        padding: 0;
                        font-size: 12px;
                        padding-left: 3px;
                      }
                      .ui-tag__icon {
                        ui-icon {
                          padding: 0;
                          font-size: 15px;
                          padding-left: 0;
                        }
                      }
                    }
                  }
                  .task-type,
                  .task-status {
                    padding: 0 2.5px;
                    .ui-tag {
                      // background-color: $purple-very-light !important;
                      // color: $blue-mid !important;
                      padding: 4px 15px;
                      margin: 0;
                      .ui-tag__label {
                        padding: 0;
                        font-size: 12px;
                        gap: 2px;
                        line-height: 15px;
                      }
                    }
                  }
                  &.task-diff {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 10px;
                    color: $gray-light;
                    padding-top: 10px;
                  }
                }
              }

              .task-header {
                display: flex;
                justify-content: space-between;
                align-items: start;
                padding-top: 5px;
                .task-link-name,
                .task-title {
                  line-height: initial;
                }
                .task-link-name {
                  color: $gray-mid;
                  font-size: 12px;
                }
                .task-title {
                  font-size: 16px;
                }
                ui-icon {
                  color: $gray-light;
                }
              }
            }
          }
        }
      }
      ui-button[data-type="tool"].btn-floating-project {
        position: absolute;
        padding: 5px;
        border-radius: 50%;
        bottom: 10px;
        right: 10px;
      }
      // .rounded {
      //   position: relative;
      // }
    }
  }
}
