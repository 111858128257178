import { Shared } from "generated";

export class TaskDTO {
  id: number;
  taskType: Shared.Model.EnumerationDTO;
  title: string;
  description: string;
  startDateTime: Date | null;
  endDateTime: Date | null;
  deadlineDate: Date | null;
  deadlineDateType: number | string | null;
  taskStatus: Shared.Model.EnumerationDTO;
  taskResultComment: string;
  //taskCriticityLevelId: number | null;
  taskCriticityLevel: Shared.Model.EnumerationDTO;

  linkName: string;
  deadlineBackgroundColor: string;
  deadlineTextColor: string;
  projectPhase: Shared.Model.EnumerationDTO;

  isSubTask: boolean;
  parentTaskId: number | null;

  responsibleId: number;
  responsibleUri: string;
  responsibleFullName: string;

  projectId: number;

  createdById: number | null;
  createdByFullName: string;
  createdByUri: string;
  createdTime: Date;

  updatedByFullName: string;
  updatedTime: Date;
  updatedById: number | null

  path: string;
  hasChildren: boolean;
  subTasks: number[];
  subTaskTitles: string[];
  taskTagIds: number[];
  taskTags: Shared.Model.EnumerationDTO[];
  taskJobFiles: TaskFileDTO[];
}

export interface TaskFileDTO {
  id: number;
  url: string;
  name: string;
  description: string;
  size: number;
  fileTypeId: number | null;
  fileTypeName: string;
  taskJobId: number | null;
}

export class TaskOverviewDTO {
  name: string;
  value: number;
  color: string;
}

export class MonthlyExecutionOverviewDTO {
  public outgoingsAmounts: Array<[date: Date, value: number]>;
  public progressStatesInitialAmounts: Array<[date: Date, value: number]>;
  public progressStatesPayOutAmounts: Array<[date: Date, value: number]>;
  public billedAmounts: Array<[date: Date, value: number]>;
  public realMargins: Array<[date: Date, value: number]>;
  public forecastMargin: number;
}
