import { DialogCancellableOpenResult, DialogOpenResult } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, Various, IMenuGroup, PictureHelper, EnumerationTypeService, DialogBoxViewModel, ActionDialogBoxInputParameters, IFile, GlobalLoaderService, ListViewModelBase, FieldType, EnumerationType, EntityManager, KeyboardShortcut } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, computedFrom, Container, TaskQueue } from 'aurelia-framework';
import * as Constants from '../constants';
import { IdsToCompareSingleton } from 'resources/utilities/IdsToCompareSingleton';
import { EmailSenderBox } from 'email-sender/email-sender-box';
import * as toastr from 'toastr';
import { ProjectApiService } from 'services/project-api-service';
import { ColDef, ICellRendererParams, GridOptions } from 'ag-grid-community';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';
import { ModuleListTreeData } from 'module-list-tree-data/module-list-tree-data';
import { AddRequestSupplierOffer } from './add-request-supplier-offer';
import { EditRequestSupplierOffer } from './edit-request-supplier-offer/edit-request-supplier-offer';
import { SupplierOfferApiService } from 'services/supplier-offer-api-service';
import { RequestSupplierApiService } from 'services/request-supplier-api-service';
import { QuillDialog } from 'quill-dialog/quill-dialog';

@autoinject
export class RequestSupplierOfferDetail extends ListViewModelBase<Merlin.Web.Model.ComputedRequestToSupplier> {
  public ressourceName: string = Constants.EntityTypeNames.ComputedRequestToSupplier;
  // public documentTitle: string;


  public requestSupplierOfferService: ServiceBase<Merlin.Web.Model.RequestSupplierOffer>;
  public requestToSupplierService: ServiceBase<Merlin.Web.Model.RequestToSupplier>;
  public requestSupplierOfferActivityCodesService: EnumerationTypeService;
  public subcontractorOfferSourceService: EnumerationTypeService;
  public requestToSupplierStatusService: EnumerationTypeService;
  public sourceService: EnumerationTypeService;
  public entity: Merlin.Web.Model.RequestSupplierOffer
  public entityManager: EntityManager;
  private menuItems: Array<IMenuGroup>;
  private nbDecimalForPriceDisplay: number

  private sourceList: Array<EnumerationType>;
  private statusList: Array<EnumerationType>;
  private canSave: boolean = false
  public appParameter: Merlin.Web.Model.ApplicationParameter;
  public appParamService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  public specificConditionsService: ServiceBase<Merlin.Web.Model.ContentTemplate>;
  private supplierOfferService: SupplierOfferApiService

  workQuotationVersionId: any;
  projectId: any;

  type: DocumentTypeDiscriminator;
  public hasChangesSubscriptionKey: number;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private task: TaskQueue, private fileHelper: PictureHelper, private gls: GlobalLoaderService, private idsToCompareSingleton: IdsToCompareSingleton, private projectApiService: ProjectApiService, private requestSupplierApiService: RequestSupplierApiService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.ComputedRequestToSupplier>(Constants.EntityTypeNames.ComputedRequestToSupplier));
    this.requestSupplierOfferActivityCodesService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.requestToSupplierStatusService = new EnumerationTypeService(Constants.EnumerationTypes.RequestToSupplierStatus);
    this.sourceService = new EnumerationTypeService(Constants.EnumerationTypes.SubcontractorOfferSource);
    this.entityManager = Container.instance.get(EntityManager);
    this.hasChangesSubscriptionKey = this.entityManager.manager.hasChangesChanged.subscribe((data) => {
      this.canSave = data.hasChanges;
    });
    this.appParamService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);

    this.specificConditionsService = new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate);
    this.specificConditionsService.gridDataSource.queryParameters = { contentTypeId: Constants.ContentTypeId.SpecificBuyingCondition }
    this.supplierOfferService = Container.instance.get(SupplierOfferApiService);
  }

  public async activate(params) {
    let id = params.param1;
    this.service.gridDataSource.queryParameters = { requestSupplierOfferId: params.param1 };
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.requestToSupplierService = new ServiceBase<Merlin.Web.Model.RequestToSupplier>(Constants.EntityTypeNames.RequestToSupplier);
    if (this.workQuotationVersionId != null) {
      this.type = DocumentTypeDiscriminator.WorkQuotationVersion;
      this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer>(Constants.EntityTypeNames.WorkQuotationVersionRequestSupplierOffer);
    }

    this.projectId = params.projectId;

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;

    if (this.projectId != null) {
      if (this.type == null) {
        this.type = DocumentTypeDiscriminator.Buying;
      }
      if (this.service == null) {
        this.service.gridDataSource.queryParameters = { projectId: params.projectId };
      }
      if (this.requestSupplierOfferService == null)
        this.requestSupplierOfferService = new ServiceBase<Merlin.Web.Model.BuyingRequestSupplierOffer>(Constants.EntityTypeNames.BuyingRequestSupplierOffer);
    }
    this.entity = await this.requestSupplierOfferService.getEntityById(id, 'requestSupplierOfferActivityCodes.activityCode', 'requestSupplierOfferFiles', 'specificConditions.contentTemplate');

    this.setMenuItems();
    this.sourceList = await this.sourceService.getAll();

    this.statusList = await this.requestToSupplierStatusService.getAll();
    this.gridOptions.rowClassRules = {
      'ag-row-disabled': (params) => {
        return params.data?.statusId == Constants.RequestToSupplierStatusId.NotFollowed
      }
    }

    this.appParameter = await this.appParamService.firstEntity();
    this.loadGeneralConditionsFile();
    await super.activate(params);
  }
  @computedFrom('entity', 'entity.requestOfferName')
  public get documentTitle() {
    return this.entity.requestOfferName;
  }

  @computedFrom('entity', 'entity.requestOfferName')
  public get ribbonHeaderText() {
    return this.entity.requestOfferID;
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.ComputedRequestToSupplier) {
    if (entity?.id) {
      if (entity.supplierOfferId == null) {
        this.task.queueTask(async () => {
          this.goToSupplierOfferDetail(entity);
        });
        return null;
      }
      return this.appendQueryParams('supplier-offer-detail/' + entity.id + '/' + entity.supplierOfferId + '/' + this.type.toString());
    }
    return null;
  }

  async goToSupplierOfferDetail(entity: Merlin.Web.Model.ComputedRequestToSupplier) {
    let supplierOfferId = entity.supplierOfferId;
    if (this.supplierOfferService == null) throw new Error('supplier service is null');
    if (supplierOfferId == null) {
      this.supplierOfferService.type = this.type;
      supplierOfferId = await this.supplierOfferService.createSupplierOffer(entity.id, entity.supplierId);
    }
    return this.router.navigate('supplier-offer-detail/' + entity.id + '/' + supplierOfferId + '/' + this.type.toString());
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    this.gridOptions.components.customButtonRenderer = CustomButtonRenderer;
    let defs: ColDef[] = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          menuItems: (params: ICellRendererParams) => this.getMenuItems != null ? this.getMenuItems(params) : [],
          disabledMenuItem: (this as any).isSelectable,
          list: (this as any),
          canComment: false
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: this.i18n.tr("requesttosupplier.toSupplierOrSubContractorId"),
        field: "supplierName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("requesttosupplier.toSupplierContactPersonId"),
        field: "contactPersonName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("notificationsent.date"),
        field: "sendDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        colId: "sourceId",
        headerName: this.i18n.tr("supplieroffer.source"),
        field: "sourceId",
        valueGetter: (params) => params?.data?.source._translation,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.sourceList, ["denomination", "_translation"]),
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.SubcontractorOfferSource,
          customdFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "sourceId"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.SubcontractorOfferSource,
          customdFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "sourceId"
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        colId: "statusId",
        headerName: this.i18n.tr("requesttosupplier.statusId"),
        field: "statusId",
        valueGetter: (params) => params?.data?.status._translation,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.statusList, ["denomination", "_translation"]),
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.RequestToSupplierStatus,
          customdFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "statusId"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.RequestToSupplierStatus,
          customdFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "statusId"
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("supplieroffer.receivedDateTime"),
        field: "receiptDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("supplieroffer.completeness"),
        field: "totalCompletedLeafLine",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return `<span>${(currentThis.params?.data?.totalCompletedLeafLine != null && currentThis.params?.data?.totalLeafLine) ? currentThis.params?.data?.totalCompletedLeafLine + '/' + currentThis.params?.data?.totalLeafLine : ''}</span>`
          }
        }
      },
      {
        headerName: this.i18n.tr("supplieroffer.amount"),
        field: "totalPrice",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
      }
    ];
    return defs;
  }

  public async sendEmailToMultipleSupplier(suppliers: Merlin.Web.Model.ComputedRequestToSupplier[]) {
    let files: Array<IFile> = new Array();
    files.push(...await this.getFilesToSend(suppliers[0].supplierOfferId));

    let emailContext: any;
    if (this.entity.requestOfferTypeId == Constants.SupplierOfferTypeId.ForPriceOffer) {
      emailContext = Constants.EmailContextId.SupplierOfferRequest;
    } else if (suppliers[0].sourceId == Constants.SubcontractorOfferSourceId.FromClientOffer) {
      emailContext = Constants.EmailContextId.SupplierOfferConfirmationForBuying;
    } else {
      emailContext = Constants.EmailContextId.SupplierOfferRequestForBuying;
    }
    let ids = suppliers.map(x => x.id);
    let supplierIds = suppliers.map(x => x.supplierId);

    this.getSendEmailBox(ids, supplierIds, supplierIds, files, emailContext, false);
  }

  public async sendEmail(currentThis: Merlin.Web.Model.ComputedRequestToSupplier, resend: boolean = false) {
    let files: Array<IFile> = await this.getFilesToSend(currentThis.supplierOfferId);

    let emailContext: any;
    if (this.entity.requestOfferTypeId == Constants.SupplierOfferTypeId.ForPriceOffer) {
      emailContext = Constants.EmailContextId.SupplierOfferRequest;
    } else if (currentThis.sourceId == Constants.SubcontractorOfferSourceId.FromClientOffer) {
      emailContext = Constants.EmailContextId.SupplierOfferConfirmationForBuying;
    } else {
      emailContext = Constants.EmailContextId.SupplierOfferRequestForBuying;
    }
    this.getSendEmailBox([currentThis.id], [currentThis.supplierId], [currentThis.supplierId], files, emailContext, resend);
  }

  private async getFilesToSend(supplierOfferId: number) {
    let file: Array<IFile> = [];
    let response = await this.httpClient.get(Constants.Application.RequestSupplierController.GenerateExcel.format(this.type.toString(), this.entity.id.toString(), supplierOfferId?.toString()));
    if (response.ok) {
      let url: ExcelResult = await response.json();
      let urlSplit = url.url.split("/");
      let name = decodeURI(urlSplit[urlSplit.length - 1]);
      file.push({
        url: url.url,
        name: name,
        description: null,
        fileTypeId: null,
        size: 0,
      } as IFile);
    }

    let files: Array<IFile>;
    if (this.entity.requestOfferTypeId == Constants.SupplierOfferTypeId.ForPriceOffer) {
      files = (this.entity.requestSupplierOfferFiles.length == 0 ? file : this.entity.requestSupplierOfferFiles);
    } else {
      files = file;
      let response = await this.httpClient.get(Constants.Application.RequestSupplierController.GetConditions.format(this.type.toString(), this.entity.id.toString(), supplierOfferId?.toString()));
      if (response.ok) {
        let urls: Array<ExcelResult> = await response.json();
        urls.forEach(x => {
          file.push({
            url: x.url,
            name: x.name,
            description: null,
            fileTypeId: null,
            size: 0,
          } as IFile);
        });
      }
    }
    return files;
  }

  private getSendEmailBox(ids: number[], idsToSend: number[], companyIds: number[], files: IFile[], emailContext: any, resend: boolean) {
    this.box.showEditDialog(
      EmailSenderBox,
      null,
      this.i18n.tr('requestsupplieroffer.resendMail'), {
      canSave: false,
      actions: [
        {
          label: this.i18n.tr("notificationsent.sendEmail"),
          title: this.i18n.tr("notificationsent.sendEmail"),
          theme: "primary",
          type: "solid",
          closeDialog: false,
          fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
            await dialogBoxViewModel.customVM.currentViewModel.sendEmail();
          }
        } as ActionDialogBoxInputParameters
      ],
      size: 'all',
      model: {
        isReadonly: false,
        idsToSend: idsToSend,
        companyIds: companyIds,
        body: this.entity.emailBodyContentHtml,
        subject: this.entity.emailSubject,
        files: files,
        emailContext: emailContext,
        projectId: this.getProjectId(),
        previewRequestParams: {
          projectId: this.getProjectId(),
          requestSupplierOfferId: this.entity.id
        } as Merlin.Web.Model.EmailTemplatePreviewRequest,
        type: this.type
      }
    }).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        if (result.output != null) {
          for (const id of ids) {
            let rts = await this.requestToSupplierService.getEntityById(id, 'status');
            if (rts.requestMailId != null) {
              rts.reminderMailId = result.output[rts.toSupplierOrSubContractorId];
            }
            else {
              rts.requestMailId = result.output[rts.toSupplierOrSubContractorId];
            }
            await this.save();
            await this.requestSupplierApiService.setStatus(this.type, id, Constants.RequestToSupplierStatusId.Sent);
          }
          this.gridOptions.api.refreshInfiniteCache();
        }
      }
    });
  }

  private getProjectId() {
    return this.type == DocumentTypeDiscriminator.WorkQuotationVersion ?
      (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersion?.projectId :
      (this.entity as Merlin.Web.Model.BuyingRequestSupplierOffer)?.projectId
  }
  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("notificationsent.reSendEmail"),
            icon: "digi-mail-send-line",
            hidden: () => { return params.data.requestMailId == null },
            handler: async () => {
              await this.sendEmail(params.data, true);
            }
          },
          {
            label: this.i18n.tr("notificationsent.sendEmail"),
            icon: "digi-mail-send-line",
            hidden: () => { return params.data.requestMailId != null },
            handler: async () => {
              await this.sendEmail(params.data, false);
            }
          },
          {
            label: this.i18n.tr("supplieroffer.encode"),
            icon: "digi-barcode-box-line",
            hidden: () => { return params.data.supplierOfferId != null },
            handler: () => {
              this.encodeSupplierOffer(params.data);
            }
          },
          {
            label: this.i18n.tr("supplieroffer.edit"),
            icon: "digi-barcode-box-line",
            hidden: () => { return params.data.supplierOfferId == null },
            handler: () => {
              this.editSupplierOffer(params.data);
            }
          },
          {
            label: this.i18n.tr("supplieroffer.validateOffer"),
            icon: "digi-valid",
            hidden: () => { return params.data.sourceId != Constants.SubcontractorOfferSourceId.FromClientOffer },
            handler: async () => {
              await this.validateOffer(params.data);
            }
          },
          {
            label: this.i18n.tr("priceoffer.changeStatus"),
            icon: "digi-exchange-line",
            items: [
              {
                label: this.i18n.tr("requesttosupplierstatus.notFollowed"),
                handler: async () => {
                  await this.setStatus(params, Constants.RequestToSupplierStatusId.NotFollowed);
                },
                disabled: () => {
                  return params.data.statusId == Constants.RequestToSupplierStatusId.NotFollowed || params.data.statusId == Constants.RequestToSupplierStatusId.Followed
                }
              },
              {
                label: this.i18n.tr("requesttosupplierstatus.followed"),
                handler: async () => {
                  await this.setStatus(params, Constants.RequestToSupplierStatusId.Followed);
                },
                disabled: () => {
                  return params.data.statusId == Constants.RequestToSupplierStatusId.NotFollowed || params.data.statusId == Constants.RequestToSupplierStatusId.Followed
                }
              },

              {
                label: this.i18n.tr("requesttosupplierstatus.unprocessed"),
                handler: async () => {
                  await this.setStatus(params, Constants.RequestToSupplierStatusId.Unprocessed);
                },
                disabled: () => {
                  return params.data.statusId == Constants.RequestToSupplierStatusId.Sent || params.data.statusId == Constants.RequestToSupplierStatusId.Unprocessed || params.data.statusId == Constants.RequestToSupplierStatusId.InProcess
                }
              },
              {
                label: this.i18n.tr("requesttosupplierstatus.inProcess"),
                handler: async () => {
                  await this.setStatus(params, Constants.RequestToSupplierStatusId.InProcess);
                },
                disabled: () => {
                  return params.data.statusId == Constants.RequestToSupplierStatusId.Sent || params.data.statusId == Constants.RequestToSupplierStatusId.Unprocessed || params.data.statusId == Constants.RequestToSupplierStatusId.InProcess
                }
              },
              {
                label: this.i18n.tr("requesttosupplierstatus.sent"),
                handler: async () => {
                  await this.setStatus(params, Constants.RequestToSupplierStatusId.Sent);
                },
                disabled: () => {
                  return params.data.statusId == Constants.RequestToSupplierStatusId.Sent || params.data.statusId == Constants.RequestToSupplierStatusId.Unprocessed || params.data.statusId == Constants.RequestToSupplierStatusId.InProcess
                }
              },
            ],
            hidden: () => {
              return params.data.statusId == Constants.RequestToSupplierStatusId.OfferReceived || params.data.statusId == Constants.RequestToSupplierStatusId.Validated || params.data.statusId == Constants.RequestToSupplierStatusId.PartiallyValidated
            }
          },
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-delete-bin-line",
            handler: async () => {
              await this.deleteRequestToSupplier(params.data);

            }
          }
        ]
      }
    ]
  }


  private async setStatus(params: any, statusId: number) {
    await this.requestSupplierApiService.setStatus(params.list.type, params.data.id, statusId);
    (params.gridOptions as GridOptions).api.refreshInfiniteCache();
  }

  public async deleteRequestToSupplier(currentThis: Merlin.Web.Model.ComputedRequestToSupplier, askQuestion = true) {
    let responseCanDelete = await this.httpClient.get(Constants.Application.RequestSupplierController.CanDeleteRequestToSupplier.format(this.type.toString(), currentThis.requestSupplierOfferId.toString(), currentThis.id.toString()));
    if (responseCanDelete.ok) {
      let canDelete = await responseCanDelete.json()
      if (canDelete) {
        if (askQuestion) {
          let buttonYes: ActionDialogBoxInputParameters =
          {
            label: this.i18n.tr("general.yes", { ns: "common" }),
            title: this.i18n.tr("general.yes", { ns: "common" }),
            theme: 'primary',
            type: 'solid',
            disabled: false,
            fn: (thisBox: DialogBoxViewModel) => {
              thisBox.controller.ok(true);
            }
          };
          let buttonNo: ActionDialogBoxInputParameters =
          {
            label: this.i18n.tr("general.no", { ns: "common" }),
            title: this.i18n.tr("general.no", { ns: "common" }),
            theme: 'dark',
            type: 'ghost',
            disabled: false,
            fn: (thisBox: DialogBoxViewModel) => {
              thisBox.controller.ok(false);
            }
          };
          await this.box.showQuestion(this.i18n.tr('requestsupplieroffer.confirmDelete'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
            async (result) => {
              if (!result.wasCancelled) {
                if (result.output == true) {
                  await this.deleteEntity(currentThis);
                }
              }
            }
          )
        }
        else {
          await this.deleteEntity(currentThis);
        }

      } else {
        this.box.showWarning(this.i18n.tr('requestsupplieroffer.notCanDeleteRequestToSupplier'), this.i18n.tr('general.warningTitle'));
        return [];
      }
    }
  }

  private async deleteEntity(currentThis: Merlin.Web.Model.ComputedRequestToSupplier) {
    let response = await this.requestSupplierApiService.deleteRequestToSupplier(this.type, currentThis.requestSupplierOfferId, currentThis.id);
    if (response.ok) {
      this.gridOptions.api.onFilterChanged();
    }
    else {
      toastr.error(await response.json());
    }
  }

  public async deleteSelectedEntities() {
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr('requestsupplieroffer.confirmDelete'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
      async (result) => {
        if (!result.wasCancelled) {
          if (result.output == true) {
            this.selectedEntities.forEach(async (entity) => {
              await this.deleteRequestToSupplier(entity, false);
            })
          }
        }
      }
    );

    this.selectedEntities.splice(0);
  }

  async validateOffer(currentThis: Merlin.Web.Model.ComputedRequestToSupplier) {
    let response = await this.requestSupplierApiService.SetSupplierSource(this.type, currentThis.supplierOfferId);
    if (response.ok) {
      this.refreshAfterDelete()
    }
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("requestsupplieroffer.addNewSupplierOffer"),
            icon: "digi-add-circle-line",
            handler: () => {
              this.addSupplier();
            },
          },
          {
            label: this.i18n.tr("requestsupplieroffer.editRequestSupplierOffer"),
            icon: "digi-file-edit-line",
            handler: async () => {
              await this.editRequestSupplier();
            },
          },
          {
            label: this.i18n.tr("requestsupplieroffer.generaredXlsx"),
            icon: "digi-xlsx-file",
            handler: () => {
              this.generateXlsx();
            },
          },
          {
            label: this.i18n.tr("requestsupplieroffer.updateQuantity"),
            icon: "digi-synchronization",
            handler: () => {
              this.updateQuantity();
            },
          },
          {
            label: this.i18n.tr("requestsupplieroffer.goToComparison"),
            icon: "digi-table-line",
            handler: () => {
              this.goToComparison();
            },
            disabled: () => {
              return this.selectedEntities.filter(x => x.supplierOfferId != null).length < 2;
            }
          },
          {
            label: this.i18n.tr("notificationsent.sendEmail"),
            icon: "digi-mail-send-line",
            handler: () => {
              this.sendEmailToMultipleSupplier(this.selectedEntities);
            },
            disabled: () => {
              return this.selectedEntities.length < 1;
            }
          }
        ]
      }
    ];
  }
  async editRequestSupplier() {
    await this.box.showCustomDialog(
      EditRequestSupplierOffer,
      {
        workQuotationVersionId: this.workQuotationVersionId != null ? parseInt(this.workQuotationVersionId) : null,
        projectId: parseInt(this.projectId),
        requestSupplierOfferId: this.entity.id,
        type: this.type
      } as any,
      this.i18n.tr('requestsupplieroffer.addrequestsupplieroffer'),
      {
        canSave: false,
        size: 'all',
        containerClass: "container-add-request-supplier-offer"
      }
    ).whenClosed(result => {
      this.refreshAfterDelete();
    });
  }
  public addSupplier() {
    this.box.showCustomDialog(
      AddRequestSupplierOffer, {
        workQuotationVersionId: (this.workQuotationVersionId != null ? parseInt(this.workQuotationVersionId) : null),
        projectId: (this.projectId != null ? parseInt(this.projectId) : null),
        requestSupplierOfferId: this.entity.id
      } as any, this.i18n.tr('requestsupplieroffer.addrequestsupplieroffer'),
      {
        canSave: false,
        size: 'all',
        containerClass: "container-add-request-supplier-offer",
        callback: (currentEntity: Merlin.Web.Model.RequestSupplierOffer) => {
          (currentEntity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionId = parseInt(this.workQuotationVersionId);
          (currentEntity as Merlin.Web.Model.BuyingRequestSupplierOffer).projectId = parseInt(this.projectId);
        }
      }).whenClosed(async result => {
        this.refreshAfterDelete()
        if (!result.wasCancelled) {
          let newRequestSupplierOffer = result.output?.newRequestSupplierOffer;
          if (newRequestSupplierOffer) {
            this.addSupplier();
          }
        }
        else {
          this.entity.step = 5;
          await this.save();
        }
      });
  }
  public goToComparison() {
    this.idsToCompareSingleton.setIds(this.selectedEntities.map(x => x.supplierId));

    this.router.navigateToRoute("comparison", {
      workQuotationVersionId: (this.entity as Merlin.Web.Model.WorkQuotationVersionRequestSupplierOffer).workQuotationVersionId,
      requestOfferId: this.entity.id
    })
  }

  public async updateQuantity() {
    this.gls.allow();
    let result = await this.httpClient.post(Constants.Application.RequestSupplierController.SyncProposedQuantity.format(this.type.toString(), this.entity.id.toString()));
    if (result.ok) {
      let nbLineUpdated = await result.json();
      this.box.showNotification(this.i18n.tr('requestsupplieroffer.updateQuantityResult') + nbLineUpdated, this.i18n.tr('requestsupplieroffer.updateQuantityFinish'));
    }

  }

  private encodeSupplierOffer(requestToSupplier: Merlin.Web.Model.ComputedRequestToSupplier) {
    this.goToSupplierOfferDetail(requestToSupplier);
  }
  private editSupplierOffer(requestToSupplier: Merlin.Web.Model.ComputedRequestToSupplier) {
    let url = 'supplier-offer-detail/' + requestToSupplier.id + '/' + requestToSupplier.supplierOfferId + '/' + this.type.toString();
    this.router.navigate(this.appendQueryParams(url));
  }
  private appendQueryParams(url: string) {
    if (this.router?.currentInstruction?.queryString?.length > 0) {
      url += '?' + this.router.currentInstruction.queryString;
    }
    return url;
  }
  private async generateXlsx() {
    this.gls.allow(true, 50);
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    this.box.showQuestion(this.i18n.tr('requestsupplieroffer.excelRefQuestion'), this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes]).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        let useExcelRef = result.output;
        let response = await this.httpClient.get(Constants.Application.RequestSupplierController.GenerateExcel.format(this.type.toString(), this.entity.id.toString(), "0", useExcelRef.toString()));
        if (response.ok) {
          let url = await response.json();
          this.fileHelper.downloadFile({ url: url.url });
        }
      }
    })

  }
  private async save() {
    await this.requestSupplierOfferService.save();
  }
  private async cancel() {
    this.requestSupplierOfferService.cancel();
  }
  public deactivate() {
    this.entityManager.manager.hasChangesChanged.unsubscribe(this.hasChangesSubscriptionKey);
  }
  public async navigateBack() {
    let result = await this.canDeactivate();
    if (result) {
      this.doNavigateBack();
    }
  }
  protected doNavigateBack() {
    if ((this.router.history as any).history.length == 1) {
      this.router.navigate('');
    } else {
      this.router.navigateBack();
    }
  }
  public canDeactivate() {
    var msg = this.i18n.tr("general.doYouWantToSave", { ns: "common" });

    let buttonSave: ActionDialogBoxInputParameters = {
      label: this.i18n.tr("general.save", { ns: "common" }),
      title: this.i18n.tr("general.save", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox) => { thisBox.controller.ok(true) },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonIgnore: ActionDialogBoxInputParameters = {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox) => { thisBox.controller.ok(false) },
      keyboardShortcut: [KeyboardShortcut.Escape, KeyboardShortcut.Backspace]
    };

    var p = new Promise<boolean>((resolve, reject) => {
      if (!this.canSave === true) {
        return this.cancel()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      }
      if (this.canSave === true) {
        var asksToSave = () => {
          var confirm = (dor: DialogCancellableOpenResult) => {
            (<DialogOpenResult>dor).closeResult.then((result) => {
              if (!result.wasCancelled) {
                if (result.output === true) {
                  this.save()
                    .then(() => {
                      // Save succeed
                      resolve(true);
                    })
                    .catch(() => {
                      // Save failed
                      resolve(false);
                    });
                } else if (result.output === false) {
                  // buttonIgnore -> cancel changes
                  this.cancel();
                  resolve(true);
                }
              } else {
                // buttonCancel -> do nothing & stay here
                resolve(false);
              }
            });
          };
          this.box.showQuestion(msg, this.config.globalConfig.applicationTitle, [buttonIgnore, buttonSave])
            .then(confirm);
        }
        asksToSave();
      } else {
        resolve(true);
      }
    });
    return p;
  }

  public editAdditionalSpecificConditionHtml(): void {
    this.box.showEditDialog(QuillDialog, null, this.i18n.tr('supplieroffer.additionalSpecificConditionHtml'),
      {
        canSave: false,
        model: { bodyText: this.entity.additionalSpecificConditionHtml },
        size: 'md',
        actions: [
          {
            label: this.i18n.tr("menu.save"),
            closeDialog: false,
            theme: "primary",
            type: "solid",
            fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
              let bodyText = await dialogBoxViewModel.customVM?.currentViewModel?.bodyText;
              this.entity.additionalSpecificConditionHtml = bodyText;
              dialogBoxViewModel.controller.ok();
            }
          } as ActionDialogBoxInputParameters
        ]
      });
  }

  public file: IFile;
  loadGeneralConditionsFile() {
    if (this.appParameter.generalConditionsFileUrl?.trim().length > 0) {
      this.file = { name: this.appParameter.generalConditionsFileName, url: this.appParameter.generalConditionsFileUrl, fileTypeId: null, description: null, size: null } as IFile;
    }
  }

  private previewDocument() {
    this.fileHelper.previewFile(this.file);
  }
}

export interface ExcelResult {
  url: string;
  name: string;
}

export enum DocumentTypeDiscriminator {
  WorkQuotationVersion = 1,
  PriceOfferVersion = 2,
  Buying = 3,
  BuyingRequestSupplierOffer = 4,
  WorkQuotationVersionRequestSupplierOffer = 5,
  BuyingSupplierOffer = 6,
  WorkQuotationVersionSupplierOffer = 7
}

