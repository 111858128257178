.chart-gantt {
  min-height: 600px;

  .grid-row-flex {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    div {
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .avatar {
    width: 25px;
    height: 25px;
  }
}
