import { CustomLogger, CountryList, FieldType } from "digiwall-lib";
import { Router } from "aurelia-router";
import { autoinject, useView } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ColDef } from "ag-grid-community";
import { GridHelperMerlin } from "resources/elements/grid-helper";

@autoinject
@useView("./country-list.html")
export class ExtendedCountryList extends CountryList {
  public buttonLabel: string = this.i18n.tr("common:country.addCountry");

  constructor(router: Router, logger: CustomLogger, i18n: I18N) {
    super(router, logger, i18n);

  }

  // get canAdd() {
  //   return true;
  // }

  // public get routePrefix(): string {
  //   return "countries/";
  // }

  override getDataGridColumns(): ColDef<any>[] {
    let colDef = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("common:country.code"),
        field: "code",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("common:country.region"),
        field: "region",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("common:country.isFavorite"),
        field: "isFavorite",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("common:country.isEU"),
        field: "isEU",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("common:locality.name") + this.i18n.tr("general.inBracketsFR"),
        field: "name.lang1",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("common:locality.name") + this.i18n.tr("general.inBracketsNL"),
        field: "name.lang2",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("common:locality.name") + this.i18n.tr("general.inBracketsEN"),
        field: "name.lang3",
        type: FieldType.String
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return colDef;
  }
}
