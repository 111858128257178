
import { autoinject, computedFrom } from "aurelia-framework";
import { Router } from "aurelia-router";
import { FilterQueryOp, Predicate } from "breeze-client";
import { Country, CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EntityTypeNames, ServiceBase, Various } from "digiwall-lib";

@autoinject
export class CountryDetail extends EntityDetailViewModelBase<Country> {
  @computedFrom("entity", "isCreationMode")
  public get documentTitle() {
    if (this.isCreationMode) {
      return this.i18n.tr("common:country.addCountry");
    }
    return this.entity.name._translation;
  }
  public ressourceName = "";

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Country>(EntityTypeNames.Country));
  }

  public async activate(params: any): Promise<void> {
    await super.activate(params);
    let id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
    }
  }
}
