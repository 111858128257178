import { TaskApiService } from './../../module-tasks/service/task-api-service';
import { DashboardService } from './../../services/dashboard-service';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, Container, createOverrideContext, ViewCompiler, ViewResources, ViewSlot } from 'aurelia-framework';
import { IAnchorTab, IMenuGroup, ServiceBase } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { Merlin } from 'generated';
import * as Constants from '../../constants';
import { DialogService } from 'aurelia-dialog';
import { PreparationTemplate } from './preparation-template';
import * as toastr from 'toastr';
import { HttpClient } from 'aurelia-fetch-client';
import { ChartPieDataMapper, ChartPieSerie, ChartGanttDataMapper, ChartGanttSerie } from 'resources/charts/chart-mappers';
import { TaskDTO } from 'module-tasks/model/module-task-model';
import CustomTaskAgGroupCellRenderer from 'module-tasks/renderer/merlin-html-header-renderer';


@autoinject
export class PreparationSummary {
  projectId: any;
  menuItems: Array<IMenuGroup>;
  tabList: IAnchorTab[];

  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private project: Merlin.Web.Model.Project;
  protected chartGanttData: Array<ChartGanttSerie>;
  protected chartPieData: Array<ChartPieSerie>;
  private chartGanttDataMapper = new ChartGanttDataMapper();
  private chartPieDataMapper = new ChartPieDataMapper();

  private allPrepaTasks: Array<TaskDTO> = [];
  private allLateTasks: Array<TaskDTO> = [];
  private allCriticalTasks: Array<TaskDTO> = [];
  private allNearTasks: Array<TaskDTO> = [];

  private alreadyRenderedTask: Array<number> = [];

  constructor(public router: Router, public i18n: I18N, public httpClient: HttpClient, private dashboardService: DashboardService, public taskApiService: TaskApiService) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  public async activate(params: any) {
    this.projectId = params.projectId;
    this.project = await this.projectService.getEntityById(this.projectId);
    this.setTabList()
    this.setMenuItems();
    let ganttData = await this.dashboardService.getPrepartionGanttTask(this.projectId);
    this.chartGanttData = this.chartGanttDataMapper.mapData(ganttData);

    this.chartPieData = await this.dashboardService.getPreparationOverviewTask(this.projectId);
    this.taskApiService.projectId = this.projectId;
    this.taskApiService.projectPhaseId = Constants.ProjectPhaseId.Preparation;
    this.allPrepaTasks = await this.taskApiService.fetch({}, null, null);
    this.allLateTasks = this.allPrepaTasks.filter(x => x.deadlineDateType == Constants.TaskDeadlineType.Overdue);
    this.allCriticalTasks = this.allPrepaTasks.filter(x => x.deadlineDateType == Constants.TaskDeadlineType.Critical);
    this.allNearTasks = this.allPrepaTasks.filter(x => x.deadlineDateType == Constants.TaskDeadlineType.Near);
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      }
    );
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("workingsitepreparationtemplate.choosePreparationTemplate"),
            icon: "digi-task-line",
            handler: async () => {
              this.chooseWorkingTemplate();
            },
            hidden: this.project.workingSitePreparationTemplateName != null
          }
        ]
      }
    ];
  }

  private async chooseWorkingTemplate() {
    let dialogService = Container.instance.get(DialogService);
    await dialogService.open({
      viewModel: PreparationTemplate,
      lock: true,
      keyboard: true,
      rejectOnCancel: true,
    }).whenClosed(async x => {
      if (!x.wasCancelled) {
        let result = await this.httpClient.get(Constants.Application.ProjectController.ApplyTemplate.format(this.projectId, x.output));
        if (result.ok) {
          toastr.success(this.i18n.tr("workingsitepreparationtemplate.templateApplied"));
          this.project = await this.projectService.getEntityById(this.projectId);
          this.setMenuItems();
        }
        else {
          toastr.error(await result.text());
        }
      }
    })
  }

  private getTaskHtml(task: TaskDTO, currentDiv: HTMLDivElement) {
    if (this.alreadyRenderedTask.some(id => id == task.id)) return;
    let eGui = document.createElement('div');
    eGui.style.width = '100%';
    eGui.style.height = '100%';

    let content: string = CustomTaskAgGroupCellRenderer.getHtml(task, false, this.i18n);
    let html: string = `<template>${content}</template>`;

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    let view = viewFactory.create(Container.instance);
    let viewSlot = new ViewSlot(eGui, true);
    viewSlot.add(view);
    view.bind(this, createOverrideContext(this));
    viewSlot.attached();

    currentDiv.appendChild(eGui);
    this.alreadyRenderedTask.push(task.id);
  }

  @computedFrom("projectId")
  get ribbonHeaderText(): string {
    return this.i18n.tr("preparationindex.summary")
  }
  goToTask() {
    this.router.navigate(`/projects/${this.projectId}/preparation/module-tasks?projectPhaseId=252`);
  }
}
