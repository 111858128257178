import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject
export class ChartConfig {

  constructor(private i18n: I18N) {

  }

  public getLangOptions(): Highcharts.LangOptions {
    return {
      "loading": this.i18n.tr('highcharts.loading'),
      "months": [
        this.i18n.tr("month.january"),
        this.i18n.tr("month.february"),
        this.i18n.tr("month.march"),
        this.i18n.tr("month.april"),
        this.i18n.tr("month.may"),
        this.i18n.tr("month.june"),
        this.i18n.tr("month.july"),
        this.i18n.tr("month.august"),
        this.i18n.tr("month.september"),
        this.i18n.tr("month.october"),
        this.i18n.tr("month.november"),
        this.i18n.tr("month.december")
      ],
      "shortMonths": [
        this.i18n.tr("shortmonth.january"),
        this.i18n.tr("shortmonth.february"),
        this.i18n.tr("shortmonth.march"),
        this.i18n.tr("shortmonth.april"),
        this.i18n.tr("shortmonth.may"),
        this.i18n.tr("shortmonth.june"),
        this.i18n.tr("shortmonth.july"),
        this.i18n.tr("shortmonth.august"),
        this.i18n.tr("shortmonth.september"),
        this.i18n.tr("shortmonth.october"),
        this.i18n.tr("shortmonth.november"),
        this.i18n.tr("shortmonth.december")
      ],
      "weekdays": [
        this.i18n.tr("weekday.sunday"),
        this.i18n.tr("weekday.monday"),
        this.i18n.tr("weekday.tuesday"),
        this.i18n.tr("weekday.wednesday"),
        this.i18n.tr("weekday.thursday"),
        this.i18n.tr("weekday.friday"),
        this.i18n.tr("weekday.saturday")
      ],
      "decimalPoint": this.i18n.tr('highcharts.decimalPoint'),
      "resetZoom": this.i18n.tr('highcharts.resetZoom'),
      "resetZoomTitle": this.i18n.tr('highcharts.resetZoomTitle'),
      "thousandsSep": this.i18n.tr('highcharts.thousandsSep'),
      "noData": this.i18n.tr('highcharts.noData'),
      "printChart": this.i18n.tr('highcharts.printChart'),
      "downloadPNG": this.i18n.tr('highcharts.downloadPNG'),
      "downloadJPEG": this.i18n.tr('highcharts.downloadJPEG'),
      "downloadPDF": this.i18n.tr('highcharts.downloadPDF'),
      "downloadSVG": this.i18n.tr('highcharts.downloadSVG'),
      "downloadCSV": this.i18n.tr('highcharts.downloadCSV'),
      "downloadXLS": this.i18n.tr('highcharts.downloadXLS'),
      "viewData": this.i18n.tr('highcharts.viewData'),
      "exitFullscreen": this.i18n.tr('highcharts.exitFullscreen'),
      "viewFullscreen": this.i18n.tr('highcharts.viewFullscreen'),
      "contextButtonTitle": this.i18n.tr('highcharts.contextButtonTitle'),
      "rangeSelectorZoom": this.i18n.tr('highcharts.rangeSelectorZoom'),
      "rangeSelectorFrom": this.i18n.tr('highcharts.rangeSelectorFrom'),
      "rangeSelectorTo": this.i18n.tr('highcharts.rangeSelectorTo'),
      "accessibility": {
        "chartContainerLabel": this.i18n.tr('highcharts.accessibility.chartContainerLabel'),
        "screenReaderSection": {
          "beforeRegionLabel": this.i18n.tr('highcharts.accessibility.screenReaderSection.beforeRegionLabel'),
          "afterRegionLabel": this.i18n.tr('highcharts.accessibility.screenReaderSection.afterRegionLabel')
        }
      }
    };
  }

  public getChartOptions(): Highcharts.ChartOptions {
    return {
      style: {
        fontFamily: '"Inter", sans-serif'
      }
    }
  }

  public getRangeSelectorButtons(): Array<Highcharts.RangeSelectorButtonsOptions> {
    return [
      {
        type: 'month',
        count: 1,
        text: this.i18n.tr("highcharts.rangeSelectorButtons.text1m"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.title1m"),
      }, {
        type: 'month',
        count: 3,
        text: this.i18n.tr("highcharts.rangeSelectorButtons.text3m"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.title3m"),
      }, {
        type: 'month',
        count: 6,
        text: this.i18n.tr("highcharts.rangeSelectorButtons.text6m"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.title6m"),
      }, {
        type: 'ytd',
        text: this.i18n.tr("highcharts.rangeSelectorButtons.textYtd"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.titleYtd"),
      }, {
        type: 'year',
        count: 1,
        text: this.i18n.tr("highcharts.rangeSelectorButtons.text1y"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.title1y"),
      }, {
        type: 'all',
        text: this.i18n.tr("highcharts.rangeSelectorButtons.textAll"),
        title: this.i18n.tr("highcharts.rangeSelectorButtons.titleAll"),
      }
    ]
  }

  public getDateTimeLabelFormats(): Highcharts.AxisDateTimeLabelFormatsOptions {
    return {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b',
      week: `${this.i18n.tr('highcharts.shortweek')} %W`,
      month: '%b \'%y',
      year: '%Y'
    };
  }
}
