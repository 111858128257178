import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various, IMenuItems } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, ICellRendererParams, RowNode } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { FilterQueryOp, Predicate } from 'breeze-client';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';

@autoinject
export class EnumerationAllTypeList extends ListViewModelBase<EnumerationType> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<EnumerationType>(Constants.EntityTypeNames.EnumerationType));
  }
  public ressourceName: string = Constants.EntityTypeNames.EnumerationType;

  private paramType: string;
  private paramRoute: string;
  private isCloseEnum: boolean = false;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
    this.gridOptions.pagination = false;
    this.gridOptions.rowModelType = "serverSide"
    this.gridOptions.groupDisplayType = "groupRows"
    this.gridOptions.serverSideDatasource = {
      getRows: async (params) => {
        let response;
        let entities;

        if (params.request.groupKeys.length == 0) {
          let predicate = this.getVisiblePredicate();
          entities = await this.service.getEntities(predicate);
          entities = entities.reduce((acc, current) => { //Remove duplicate section
            if (!acc.seen.has(this.getParameterSection(current))) {
              acc.unique.push(current);
              acc.seen.add(this.getParameterSection(current));
            }
            return acc;
          }, { unique: [] as EnumerationType[], seen: new Set<number>() }).unique; // order section
          response = entities/*.sortByProperty('category')*/;
        }

        else if (params.request.groupKeys.length == 1) {
          let predicate = this.getVisiblePredicate();
          entities = await this.service.getEntities();
          entities = entities.reduce((acc, current) => { //Remove duplicate category
            if (!acc.seen.has(current.category)) {
              acc.unique.push(current);
              acc.seen.add(current.category);
            }
            return acc;
          }, { unique: [] as EnumerationType[], seen: new Set<number>() }).unique; // order category
          entities = entities.filter(x => this.getParameterSection(x) != null && this.getParameterSection(x) == params.request.groupKeys[0])
          response = entities.sortByProperty('category');
        }

        else {
          let predicate = new Predicate('category', FilterQueryOp.Equals, params.request.groupKeys[1])
          response = await this.service.getEntities(predicate);
        }
        params.success({
          rowData: response,
          rowCount: response.length
        })
      },
    }
  }

  public async activate(params): Promise<void> {
  }

  public getDetailsUrl(self, entity: EnumerationType) {
    if (entity?.id) {
      return this.router.baseUrl + `enumeration-types/${entity.category}/${entity.id}`;
    }
    return null;
  }

  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: (params as any).i18n.tr("enumerationtype.addEnumerationType"),
            icon: "digi-plus",
            handler: (currentThis) => {
              this.router.navigate((params as any).router.baseUrl + `enumeration-types/${EnumerationAllTypeList.getRouteName(params.data.category)}/${params.data.category}/-100`);
            },
            disabled: () => {
              return EnumerationAllTypeList.IsCategoryCloseEnum(params.data.category)
            }
          },
          {
            label: (params as any).i18n.tr("menu.open"),
            icon: "digi-open",
            handler: () => {
              let url = (params as any).pathDetail + params.data.id;
              if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
                url += '?' + (<any>params).router.currentInstruction.queryString;
              }
              (params as any).router.navigate(url);
            }
          },
        ]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshServerSide();
          }
        },
        disabled: () => {
          return params.data.uniqueCode != null;
        }
      }];
  }

  public addEnumerationType() {
    this.router.navigate(this.router.baseUrl + `enumeration-types/${this.paramType}/-100`);
  }

  protected isSelectable(entity: EnumerationType): boolean {
    return !entity?.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang1, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang2, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang3, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.parameterSection"),
        field: "parameterSection",
        valueGetter: (params) => {
          return this.getParameterSection(params.data);
        },
        valueFormatter: (params) => {
          return this.getParameterSection(params.data);
        },
        type: FieldType.String,
        rowGroup: true,
        rowGroupIndex: 0,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.category"),
        field: "category",
        valueFormatter: (params) => {
          return this.getCategoryName(params.data);
        },
        /*cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            console.log(currentThis)
            let result = `<span>${this.getCategoryName(currentThis.params.data)}</span>`
          }
        },*/
        type: FieldType.Number,
        rowGroup: true,
        rowGroupIndex: 1,
        hide: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public getCategoryName(enumeration: EnumerationType): string {
    switch (enumeration.category) {
      case Constants.EnumerationTypes.Language:
        return this.i18n.tr("enumerationtype.language");
      case Constants.EnumerationTypes.Civility:
        return this.i18n.tr("enumerationtype.civility");
      case Constants.EnumerationTypes.CompanyType:
        return this.i18n.tr("enumerationtype.companyType");
      case Constants.EnumerationTypes.UnitType:
        return this.i18n.tr("enumerationtype.unitType");
      case Constants.EnumerationTypes.PriceOfferStatus:
        return this.i18n.tr("enumerationtype.priceOfferStatus");
      case Constants.EnumerationTypes.ThirdPartyType:
        return this.i18n.tr("enumerationtype.thirdPartyType");
      case Constants.EnumerationTypes.PartnerType:
        return this.i18n.tr("enumerationtype.partnerType");
      case Constants.EnumerationTypes.ThirdPartyTag:
        return this.i18n.tr("enumerationtype.thirdPartyTag");
      case Constants.EnumerationTypes.SupplierDelivery:
        return this.i18n.tr("enumerationtype.supplierDelivery");
      case Constants.EnumerationTypes.AddressType:
        return this.i18n.tr("enumerationtype.addressType");
      case Constants.EnumerationTypes.CompanyFunction:
        return this.i18n.tr("enumerationtype.companyFunction");
      case Constants.EnumerationTypes.UserRole:
        return this.i18n.tr("enumerationtype.userRole");
      case Constants.EnumerationTypes.ItemFeature:
        return this.i18n.tr("enumerationtype.itemFeature");
      case Constants.EnumerationTypes.ContainerType:
        return this.i18n.tr("enumerationtype.containerType");
      case Constants.EnumerationTypes.ItemType:
        return this.i18n.tr("enumerationtype.itemType");
      case Constants.EnumerationTypes.UnitPriceSourceType:
        return this.i18n.tr("enumerationtype.unitPriceSourceType");
      case Constants.EnumerationTypes.PriceOfferLineType:
        return this.i18n.tr("enumerationtype.priceOfferLineType");
      case Constants.EnumerationTypes.ItemDeliveryType:
        return this.i18n.tr("enumerationtype.itemDeliveryType");
      case Constants.EnumerationTypes.ActivityCode:
        return this.i18n.tr("enumerationtype.activityCode");
      case Constants.EnumerationTypes.PriceOfferLineCategory:
        return this.i18n.tr("enumerationtype.priceOfferLineCategory");
      case Constants.EnumerationTypes.TaskStatus:
        return this.i18n.tr("enumerationtype.taskStatus");
      case Constants.EnumerationTypes.TaskType:
        return this.i18n.tr("enumerationtype.taskType");
      case Constants.EnumerationTypes.TaskCriticityLevel:
        return this.i18n.tr("enumerationtype.taskCriticityLevel");
      case Constants.EnumerationTypes.EmailType:
        return this.i18n.tr("enumerationtype.emailType");
      case Constants.EnumerationTypes.FileType:
        return this.i18n.tr("enumerationtype.fileType");
      case Constants.EnumerationTypes.CheckpointProjectStage:
        return this.i18n.tr("enumerationtype.checkpointProjectStage");
      case Constants.EnumerationTypes.BatchProcessResultStatus:
        return this.i18n.tr("enumerationtype.batchProcessResultStatus");
      case Constants.EnumerationTypes.EmailContext:
        return this.i18n.tr("enumerationtype.emailContext");
      case Constants.EnumerationTypes.ContactPersonRole:
        return this.i18n.tr("enumerationtype.contactPersonRole");
      case Constants.EnumerationTypes.SupplierOfferType:
        return this.i18n.tr("enumerationtype.supplierOfferType");
      case Constants.EnumerationTypes.PriceOfferLineTag:
        return this.i18n.tr("enumerationtype.priceOfferLineTag");
      case Constants.EnumerationTypes.SubcontractorOfferSource:
        return this.i18n.tr("enumerationtype.subcontractorOfferSource");
      case Constants.EnumerationTypes.SupplierOfferLineStatus:
        return this.i18n.tr("enumerationtype.supplierOfferLineStatus");
      case Constants.EnumerationTypes.ModifiedQuantityStatus:
        return this.i18n.tr("enumerationtype.modifiedQuantityStatus");
      case Constants.EnumerationTypes.SupplierOfferLineType:
        return this.i18n.tr("enumerationtype.supplierOfferLineType");
      case Constants.EnumerationTypes.TaskTag:
        return this.i18n.tr("enumerationtype.taskTag");
      case Constants.EnumerationTypes.DocumentTag:
        return this.i18n.tr("enumerationtype.documentTag");
      case Constants.EnumerationTypes.ProjectPhase:
        return this.i18n.tr("enumerationtype.projectPhase");
      case Constants.EnumerationTypes.ThirdPartyContractStatus:
        return this.i18n.tr("enumerationtype.thirdPartyContractStatus");
      case Constants.EnumerationTypes.BuyingClientValidationStatus:
        return this.i18n.tr("enumerationtype.buyingClientValidationStatus");
      case Constants.EnumerationTypes.ProjectStatus:
        return this.i18n.tr("enumerationtype.projectStatus");
      case Constants.EnumerationTypes.UserFunctions:
        return this.i18n.tr("enumerationtype.userFunctions");
      case Constants.EnumerationTypes.DocumentType:
        return this.i18n.tr("enumerationtype.documentType");
      case Constants.EnumerationTypes.ContentType:
        return this.i18n.tr("enumerationtype.contentType");
      case Constants.EnumerationTypes.ProjectInternalRole:
        return this.i18n.tr("enumerationtype.projectInternalRole");
      case Constants.EnumerationTypes.ProjectExternalRole:
        return this.i18n.tr("enumerationtype.projectExternalRole");
      case Constants.EnumerationTypes.ProjectCancellationReason:
        return this.i18n.tr("enumerationtype.projectCancellationReason");
      case Constants.EnumerationTypes.ProjectType:
        return this.i18n.tr("enumerationtype.projectType");
      case Constants.EnumerationTypes.ProjectPartnerRole:
        return this.i18n.tr("enumerationtype.projectPartnerRole");
      case Constants.EnumerationTypes.RequestToSupplierStatus:
        return this.i18n.tr("enumerationtype.requestToSupplierStatus");
      case Constants.EnumerationTypes.DocumentModule:
        return this.i18n.tr("enumerationtype.documentModule");
      case Constants.EnumerationTypes.DocumentStatus:
        return this.i18n.tr("enumerationtype.documentStatus");
      case Constants.EnumerationTypes.PriceOfferMobilizationDelay:
        return this.i18n.tr("enumerationtype.priceOfferMobilizationDelay");
      case Constants.EnumerationTypes.PriceOfferPaymentDelay:
        return this.i18n.tr("enumerationtype.priceOfferPaymentDelay");
      case Constants.EnumerationTypes.PriceOfferDeposit:
        return this.i18n.tr("enumerationtype.priceOfferDeposit");
      case Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic:
        return this.i18n.tr("enumerationtype.priceOfferPriceRevisionTechnic");
      case Constants.EnumerationTypes.AdditionalWorkType:
        return this.i18n.tr("enumerationtype.additionalWorkType");
      case Constants.EnumerationTypes.ClientAdditionalWorkStatusType:
        return this.i18n.tr("enumerationtype.clientAdditionalWorkStatusType");
      case Constants.EnumerationTypes.LancelotInvitationStatus:
        return this.i18n.tr("enumerationtype.lancelotInvitationStatus");
      case Constants.EnumerationTypes.Approval:
        return this.i18n.tr("enumerationtype.approval");
      case Constants.EnumerationTypes.ProjectClass:
        return this.i18n.tr("enumerationtype.projectClass");
      case Constants.EnumerationTypes.ProgressStatementVersionStatus:
        return this.i18n.tr("enumerationtype.progressStatementVersionStatus");
      case Constants.EnumerationTypes.ThirdPartyContractType:
        return this.i18n.tr("enumerationtype.thirdPartyContractType");
      case Constants.EnumerationTypes.FileContext:
        return this.i18n.tr("enumerationtype.fileContext");
      case Constants.EnumerationTypes.SellingInvoiceType:
        return this.i18n.tr("enumerationtype.sellingInvoiceType");
      case Constants.EnumerationTypes.ProgressStatementQuantityType:
        return this.i18n.tr("enumerationtype.progressStatementQuantityType");
      case Constants.EnumerationTypes.SellingInvoiceStatus:
        return this.i18n.tr("enumerationtype.sellingInvoiceStatus");
      case Constants.EnumerationTypes.PaymentStatus:
        return this.i18n.tr("enumerationtype.paymentStatus");
      case Constants.EnumerationTypes.TransactionStatus:
        return this.i18n.tr("enumerationtype.transactionStatus");
      case Constants.EnumerationTypes.NotificationType:
        return this.i18n.tr("enumerationtype.notificationType");
      case Constants.EnumerationTypes.BuildingCosts:
        return this.i18n.tr("enumerationtype.buildingCosts");
      case Constants.EnumerationTypes.OwnWork:
        return this.i18n.tr("enumerationtype.ownWork");
      default:
        return this.i18n.tr("enumerationtype.unknown");
    }
  }

  public getParameterSection(enumeration: EnumerationType): string {
    switch (enumeration.category) {
      case Constants.EnumerationTypes.DocumentTag:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.Merlin);
      case Constants.EnumerationTypes.FileType:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.File);
      case Constants.EnumerationTypes.Civility:
      case Constants.EnumerationTypes.CompanyType:
      case Constants.EnumerationTypes.ThirdPartyType:
      case Constants.EnumerationTypes.CompanyFunction:
      case Constants.EnumerationTypes.PartnerType:
      case Constants.EnumerationTypes.ThirdPartyTag:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.ThirdParty);
      case Constants.EnumerationTypes.ProjectPartnerRole:
      case Constants.EnumerationTypes.ProjectExternalRole:
      case Constants.EnumerationTypes.ProjectInternalRole:
      case Constants.EnumerationTypes.ProjectCancellationReason:
      case Constants.EnumerationTypes.ProjectStatus:
      case Constants.EnumerationTypes.ProjectType:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.Project);
      case Constants.EnumerationTypes.PriceOfferStatus:
      case Constants.EnumerationTypes.PriceOfferLineType:
      case Constants.EnumerationTypes.ActivityCode:
      case Constants.EnumerationTypes.BuildingCosts:
      case Constants.EnumerationTypes.PriceOfferLineTag:
      case Constants.EnumerationTypes.PriceOfferMobilizationDelay:
      case Constants.EnumerationTypes.PriceOfferPaymentDelay:
      case Constants.EnumerationTypes.PriceOfferDeposit:
      case Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.PriceOffer);
      case Constants.EnumerationTypes.TaskType:
      case Constants.EnumerationTypes.TaskCriticityLevel:
      case Constants.EnumerationTypes.TaskTag:
        return this.i18n.tr(Constants.Routes.MenuSubGroup.Task);
      default:
        return null;
    }
  }

  public getVisiblePredicate(): Predicate {
    let predicate: Predicate;
    this.getVisibleEnumeration().forEach(category => {
      if (predicate == null) {
        predicate = new Predicate('category', FilterQueryOp.Equals, category)
      }
      else {
        predicate = predicate.or(new Predicate('category', FilterQueryOp.Equals, category))
      }
    });
    return predicate;
  }

  public getVisibleEnumeration(): Array<number> {
    return [
      Constants.EnumerationTypes.DocumentTag,
      Constants.EnumerationTypes.FileType,
      Constants.EnumerationTypes.Civility,
      Constants.EnumerationTypes.CompanyType,
      Constants.EnumerationTypes.ThirdPartyType,
      Constants.EnumerationTypes.CompanyFunction,
      Constants.EnumerationTypes.PartnerType,
      Constants.EnumerationTypes.ThirdPartyTag,
      Constants.EnumerationTypes.ProjectPartnerRole,
      Constants.EnumerationTypes.ProjectExternalRole,
      Constants.EnumerationTypes.ProjectInternalRole,
      Constants.EnumerationTypes.ProjectCancellationReason,
      Constants.EnumerationTypes.ProjectStatus,
      Constants.EnumerationTypes.ProjectType,
      Constants.EnumerationTypes.PriceOfferStatus,
      Constants.EnumerationTypes.PriceOfferLineType,
      Constants.EnumerationTypes.ActivityCode,
      Constants.EnumerationTypes.BuildingCosts,
      Constants.EnumerationTypes.PriceOfferLineTag,
      Constants.EnumerationTypes.PriceOfferMobilizationDelay,
      Constants.EnumerationTypes.PriceOfferPaymentDelay,
      Constants.EnumerationTypes.PriceOfferDeposit,
      Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic,
      Constants.EnumerationTypes.TaskType,
      Constants.EnumerationTypes.TaskCriticityLevel,
      Constants.EnumerationTypes.TaskTag
    ];
  }

  public static getTagHtml(text: string, textColor: string, backgroundColor: string) {
    if (!text) return '';
    return `<ui-tag label="${text}"
                  text-color="${textColor}" background-color="${backgroundColor}" size="md">
                </ui-tag>`
  }

  public static getRouteName(category: number) {
    switch (category) {
      case Constants.EnumerationTypes.Civility:
        return "civility";
      case Constants.EnumerationTypes.CompanyType:
        return "company-type";
      case Constants.EnumerationTypes.UnitType:
        return 'unit-type';
      case Constants.EnumerationTypes.PriceOfferStatus:
        return 'price-offer-status';
      case Constants.EnumerationTypes.ThirdPartyType:
        return 'third-party-type';
      case Constants.EnumerationTypes.PartnerType:
        return 'partner-type';
      case Constants.EnumerationTypes.ThirdPartyTag:
        return 'third-party-tag';
      case Constants.EnumerationTypes.CompanyFunction:
        return 'company-function';
      case Constants.EnumerationTypes.ItemFeature:
        return 'item-feature';
      case Constants.EnumerationTypes.ContainerType:
        return 'container-type';
      case Constants.EnumerationTypes.UnitPriceSourceType:
        return 'unit-price-source-type';
      case Constants.EnumerationTypes.PriceOfferLineType:
        return 'price-offer-line-type';
      case Constants.EnumerationTypes.ItemDeliveryType:
        return 'item-delivery-type';
      case Constants.EnumerationTypes.ActivityCode:
        return 'activity-code';
      case Constants.EnumerationTypes.EmailType:
        return 'email-type';
      case Constants.EnumerationTypes.FileType:
        return 'file-type';
      case Constants.EnumerationTypes.TaskType:
        return 'task-type';
      case Constants.EnumerationTypes.TaskCriticityLevel:
        return 'task-criticity-level';
      case Constants.EnumerationTypes.PriceOfferLineTag:
        return 'price-offer-line-tag';
      case Constants.EnumerationTypes.TaskTag:
        return 'task-tag';
      case Constants.EnumerationTypes.DocumentTag:
        return 'document-tag';
      case Constants.EnumerationTypes.UserFunctions:
        return 'document-tag';
      case Constants.EnumerationTypes.DocumentType:
        return 'document-tag';
      case Constants.EnumerationTypes.ProjectExternalRole:
        return 'project-external-role';
      case Constants.EnumerationTypes.ProjectPartnerRole:
        return 'project-partner-role';
      case Constants.EnumerationTypes.ProjectInternalRole:
        return 'project-internal-role';
      case Constants.EnumerationTypes.ProjectCancellationReason:
        return 'project-cancellation-reason';
      case Constants.EnumerationTypes.ProjectStatus:
        return 'project-status';
      case Constants.EnumerationTypes.ProjectType:
        return 'project-type';
      case Constants.EnumerationTypes.PriceOfferMobilizationDelay:
        return 'price-offer-mobilization-delay';
      case Constants.EnumerationTypes.PriceOfferPaymentDelay:
        return 'price-offer-payment-delay';
      case Constants.EnumerationTypes.PriceOfferDeposit:
        return 'price-offer-deposit';
      case Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic:
        return 'price-offer-price-revision-technic';
      case Constants.EnumerationTypes.BuildingCosts:
        return 'building-costs';
      default:
        return null;
    }
  }

  public static IsCategoryCloseEnum(category: number) {
    switch (category) {
      case Constants.EnumerationTypes.PriceOfferStatus:
      case Constants.EnumerationTypes.ThirdPartyType:
      case Constants.EnumerationTypes.UnitPriceSourceType:
      case Constants.EnumerationTypes.PriceOfferLineType:
      case Constants.EnumerationTypes.TaskCriticityLevel:
      case Constants.EnumerationTypes.ProjectInternalRole:
      case Constants.EnumerationTypes.ProjectStatus:
      case Constants.EnumerationTypes.ProjectType:
      case Constants.EnumerationTypes.BuildingCosts:
      case Constants.EnumerationTypes.ItemDeliveryType:
        return true;
      default:
        return false;
    }
  }
}


