import { Aurelia } from 'aurelia-framework';
import environment from './environment';
import 'breeze-client/breeze.modelLibrary.backingStore';
import 'breeze-client/breeze.dataService.webApi';
import 'breeze-client/breeze.uriBuilder.json';
import { UIFrameworkConfig } from 'digiwall-lib';
import { MerlinConfig } from './merlin-config';
import MerlinBreezeModel from 'model';
//import moment = require('moment');
import 'moment/locale/fr';
import 'moment/locale/nl';
import * as Constants from './constants';

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature('resources')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock = true;
      config.settings.centerHorizontalOnly = true;
      config.settings.startingZIndex = 1000;
      config.settings.keyboard = true;
      config.useCSS('_ux-dialog-override');
    })
    .plugin('digiwall-lib', (config: UIFrameworkConfig) => {
      config.setApplicationConfigImplementation(MerlinConfig);
      config.autoDetectAuthServiceImplementation(environment.auth);
      config.setBreezeModelImplementation(MerlinBreezeModel);
      config.setHttpClientConfiguration(config =>
        config.withBaseUrl(Constants.Application.RemoteServiceName)
      );
      config.setFileStorageServiceImplementation();
    })
    .plugin('aurelia-application-insights', config => {
      config.init('4167b083-df0f-4532-8104-2693d7e7f18b');
      config.attach({
        logging: {
          enabled: true // Set to `true` to have some log messages appear in the browser console.
        },
        logForwarding: {
          enabled: true // Set to `true` to have logged messages and exceptions sent to app inights.
        },
        pageTracking: {
          enabled: true // Set to `false` to disable in non-production environments.
        },
        clickTracking: {
          enabled: false // Set to `false` to disable in non-production environments.
        }
      });
    })
    .globalResources(
      [
        'resources/attributes/editable-attribute',
        'resources/attributes/icon-button-attribute',
        'resources/attributes/on-enter',
        'resources/value-converters/index',
        'resources/elements/ui-footer-page',
        'resources/elements/cropper/ui-cropper-picture',
        'resources/elements/ag-cell-comment/ag-cell-comment',
        'resources/elements/ag-cell-comment/ag-cell-comment-box',
        'resources/elements/ag-cell-item-tooltip/ag-cell-item-tooltip',
        'email-sender/email-sender',
        'documents/ui-document-file',
        "resources/elements/dw-quill/dw-quill",
        "resources/elements/dw-quill/dw-quill-ml",
        "projects/internal-team/internal-team",
        'resources/elements/ui-rating',
        'resources/charts/gantt/chart-gantt',
        'resources/charts/pie/chart-pie',
        'resources/charts/column/chart-column',
        'resources/charts/wrappers/monthly-execution-chart-wrapper'
      ]);

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  if (environment.testing === true) {
    aurelia.use.plugin('aurelia-testing');
  }
  aurelia.start().then(() => {
    // Timeout for logo animation
    setTimeout(() => {
      aurelia.setRoot('app');
    }, 2500);
  });
}
