import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { createOverrideContext } from "aurelia-binding";
import { Container } from "aurelia-framework";
import { View, ViewSlot, ViewCompiler, ViewResources } from "aurelia-templating";
import { Box } from "digiwall-lib";
import { ModuleListTreeData } from "module-list-tree-data/module-list-tree-data";
import { IDataLineApiService } from "services/i-data-line-api-service";

export abstract class MeteringFullWidthCellRenderer implements ICellRendererComp {
  eGui!: HTMLElement;
  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  box: Box;

  apiService: IDataLineApiService;
  listTree: ModuleListTreeData;
  versionId: number;
  protected hideAddItem: boolean = false;



  init(params) {
    this.params = params;
    this.apiService = params.apiService;
    this.listTree = params.listTree;
    this.versionId = params.versionId;
    if (params.hideAddItem != null) {
      this.hideAddItem = params.hideAddItem;
    }
    this.box = Container.instance.get(Box);
    this.setupGui();
  }

  abstract setupContentGui(): string;

  public async setupGui() {
    this.eGui = document.createElement('div');

    this.eGui.style.height = '100%';
    let content: string = this.setupContentGui();
    let html: string = `<template>${content}</template>`;

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public destroy() {
    if (this.view) this.view.unbind();
    if (this.viewSlot) this.viewSlot.detached();
  }
}
