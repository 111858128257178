.floating-project-chart {
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "chart chart"
    "month activity";
  gap: 10px;
  .chart,
  .month,
  .activity {
    border: solid 1px #dddddd;
    border-radius: 24px;
    padding: 0.5rem;
  }

  .chart {
    grid-area: chart;
  }
  .month {
    grid-area: month;
  }
  .activity {
    grid-area: activity;
  }

  .month,
  .activity {
    .currency {
      text-align: right;
    }
  }
}
