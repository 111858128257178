import { bindable, autoinject, customElement } from 'aurelia-framework';
import * as Highcharts from 'highcharts';
import { SeriesGanttOptions, CSSObject } from 'highcharts';
import gantt from 'highcharts/modules/gantt';
import { ChartConfig } from '../chart-config';
import { I18N } from 'aurelia-i18n';
import { ChartGanttSerie } from '../chart-mappers';

const
  day = 24 * 36e5,
  today = Math.floor(Date.now() / day) * day;

@autoinject
@customElement('chart-gantt')
export class ChartGantt {

  @bindable()
  private data: Array<ChartGanttSerie>;
  protected container: HTMLDivElement;
  private options: Highcharts.Options;
  private chart: Highcharts.GanttChart;
  private chartConfig: ChartConfig;
  constructor(private i18n: I18N) {
    this.chartConfig = new ChartConfig(this.i18n);
  }

  attached() {
    // Load Gantt lib
    gantt(Highcharts);

    this.setChartOptions();
    this.chart = Highcharts.ganttChart(this.container, this.options);

    // Set extremes
    this.setExtremes(21, 21);
  }

  /**
   * Set extremes 21 days before and 21 days after of today
   *
   * @private
   * @param {number} before Days before
   * @param {number} after Days after
   * @memberof ChartGantt
   */
  private setExtremes(before: number, after: number): void {
    if (this.chart != null && Array.isArray(this.data)) {
      let minDate = Math.min(...this.data.map(d => d.start));
      let maxDate = Math.max(...this.data.map(d => d.end ?? d.start));
      let daysBefore = today - (before * day);
      let daysAfter = today + (after * day);
      if (daysBefore < minDate) {
        daysBefore = minDate - (1 * day);
      }
      if (daysAfter > maxDate) {
        daysAfter = maxDate + (1 * day);
      }
      if (daysBefore && daysAfter && daysBefore < daysAfter) {
        this.chart.xAxis[0].setExtremes(daysBefore, daysAfter);
      }
    }
  }

  /**
   * Set chart type Gantt options
   *
   * @memberof ChartGantt
   */
  private setChartOptions() {
    // Set I18N
    Highcharts.setOptions({ lang: this.chartConfig.getLangOptions() });

    this.options = {
      // Credits
      credits: {
        enabled: false
      },

      // Scrollbar
      scrollbar: {
        enabled: true
      },

      // Navigator
      navigator: {
        enabled: true,
        series: {
          type: 'gantt',
          pointPadding: 0.25,
          accessibility: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          max: this.data?.length ?? 5,
          reversed: true
        }
      },

      // Range selector
      rangeSelector: {
        enabled: true,
        selected: 0,
        inputEnabled: false,
        buttons: this.chartConfig.getRangeSelectorButtons()
      },

      // Chart
      chart: Object.assign(
        {
          scrollablePlotArea: {
            minHeight: Math.max(...[this.data.length * 48, 601])
          },
          height: 600,
          marginRight: 30
        } as Highcharts.ChartOptions,
        this.chartConfig.getChartOptions()
      ),
      // PlotOptions
      plotOptions: {
        series: {
          connectors: {
            dashStyle: 'ShortDot',
            lineWidth: 2,
            radius: 5,
            startMarker: {
              enabled: false
            },
          },
          dataLabels: [{
            enabled: true,
            align: 'left',
            format: '{point.name}',
            padding: 10,
            style: {
              fontWeight: 'normal',
              textOutline: 'none'
            }
          }, {
            enabled: true,
            align: 'right',
            format: '{#if point.completed}{(multiply ' +
              'point.completed.amount 100):.0f}%{/if}',
            padding: 10,
            style: {
              fontWeight: 'normal',
              textOutline: 'none',
              opacity: 0.6
            }
          }]
        }
      },

      // Series
      series:
        [
          {
            type: 'gantt',
            name: '',
            data: this.data,
            showInNavigator: true
          } as SeriesGanttOptions
        ],

      // XAxis
      xAxis: [{
        currentDateIndicator: {
          color: '#2caffe',
          dashStyle: 'Solid',
          width: 2,
          label: {
            format: ''
          }
        },
        dateTimeLabelFormats: this.chartConfig.getDateTimeLabelFormats(),
        grid: {
          borderWidth: 0
        },
        gridLineWidth: 1,
        // min: today - (60 * day),
        // max: today + (60 * day)
      }],

      // YAxis
      yAxis: {
        width: 150,
        grid: {
          borderWidth: 0,
          columns: [
            {
              labels: {
                formatter: ctx => {
                  let data = this.data[ctx.pos];
                  return `
                  <div class="grid-row-flex">
                    <img class="avatar" src="${data?.creator}" />
                    <div title="${data?.name}">${data?.name}</div>
                  </div>`;
                },
                useHTML: true
              },
              categories: this.data.map(d => d.name)
            }
          ]
        },
        gridLineWidth: 0,
        labels: {
          style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 200 } as CSSObject,
          symbol: {
            width: 8,
            height: 6,
            x: -4,
            y: -2
          }
        },
        staticScale: 40,
      },
    }
  }
}
