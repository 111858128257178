import { I18N } from "aurelia-i18n";
import { GanttPointOptionsObject, PointOptionsObject, SeriesColumnOptions, SeriesScatterOptions, YAxisPlotLinesOptions } from "highcharts";
import { TaskDTO, TaskOverviewDTO, MonthlyExecutionOverviewDTO } from "module-tasks/model/module-task-model";
import { CHART_COLUMN_YAXIS_AMOUNTS_ID, CHART_COLUMN_YAXIS_PERCENT_ID, CHART_COLUMN_XAXIS_PLOTLINE_ID } from "./column/chart-column";
import moment from "moment";
import { ChartColumnSerie, ExecutionChartDTO } from "services/dashboard-service";

//#region Chart type Gantt
export class ChartGanttSerie implements GanttPointOptionsObject {
  public id: string;
  public name: string;
  public color?: string;
  /** The parent task id */
  public parent?: string;
  /** The previous task id */
  public dependency?: string;
  public start?: number;
  public end?: number;
  public completedPercent?: number;
  public milestone?: boolean;
  public creator?: string;
}

export class ChartGanttSerieDTO {
  public id: string;
  public name: string;
  public color?: string;
  public parent?: string;
  public start?: string | null;
  public end?: string;
  public creator: string;
}

export class ChartGanttDataMapper {
  public mapData(data: Array<ChartGanttSerieDTO>): Array<ChartGanttSerie> {
    return data.map(d => this.mapTask(d));
  }

  private mapTask(data: ChartGanttSerieDTO): ChartGanttSerie {
    return {
      id: data.id.toString(),
      name: data.name,
      parent: data.parent,
      start: data.start != null ? moment(data.start).toDate().getTime() : moment(data.end).toDate().getTime(),
      end: moment(data.end).toDate().getTime(),
      milestone: data.start == null ? true : false,
      color: data.color ?? null,
      creator: data.creator
    };
  }
}
//#endregion

//#region Chart type Pie/Donut
export class ChartPieSerie implements PointOptionsObject {
  public name: string;
  public y: number;
  public color: string;
}

export class ChartPieDataMapper {
  public mapData(data: Array<TaskOverviewDTO>): Array<ChartPieSerie> {
    return data.map(d => this.mapCategory(d));
  }

  private mapCategory(data: TaskOverviewDTO): ChartPieSerie {
    return {
      name: data.name,
      y: data.value,
      color: data.color
    }
  }
}
//#endregion

//#region Chart type Column
export class ChartColumnColumnSerie implements SeriesColumnOptions {
  type: 'column';
  yAxis: number;
  public name: string;
  public color: string;
  public tooltip: { valueSuffix: string };
  public data: Array<[x: number, y: number]>;
  public stack?: string | number;
}

export class ChartColumnScatterSerie implements SeriesScatterOptions {
  type: 'scatter';
  yAxis: number;
  marker: { radius: number }
  public name: string;
  public color: string;
  public tooltip: { valueSuffix: string, pointFormat: '{point.y}' };
  public data: Array<[x: number, y: number]>;
}

export class ChartPlotLineOption implements YAxisPlotLinesOptions {
  id: string;
  dashStyle: 'Dash';
  public color: string;
  public width: number;
  public value: number;
  public label: {
    text: string;
    align: 'right',
    x: 0,
    y: -10
  };
}

export class MonthlyExecutionChartData {
  public outgoingsAmountsSerie: ChartColumnColumnSerie;
  public progressStatesInitialAmountsSerie: ChartColumnColumnSerie;
  public progressStatesPayOutAmountsSerie: ChartColumnColumnSerie;
  public billedAmountsSerie: ChartColumnColumnSerie;
  public realMarginsSerie: ChartColumnScatterSerie;
  public forecastMarginPlotLineOption: ChartPlotLineOption;
}

export class MonthlyExecutionChartDataMapper {

  /**
   * Return a serie type Column
   *
   * @private
   * @param {Array<[date, number]>} data
   * @param {string} name
   * @param {string} color
   * @param {number} yAxis
   * @param {number} stackIndex Set the same index between series who must be stacked
   * @param {string} valueSuffix
   * @memberof MonthlyExecutionChartDataMapper
   */
  private toColumnSerie(data: ChartColumnSerie[], name: string, color: string, yAxis = 0, stackIndex: number, valueSuffix = "€"): ChartColumnColumnSerie {
    return {
      type: 'column',
      yAxis: yAxis,
      name: name,
      color: color,
      data: data.map(d => [moment(d.date).toDate().getTime(), d.value]),
      tooltip: { valueSuffix: valueSuffix },
      stack: stackIndex
    }
  }

  /**
   * Return a serie type Scatter
   *
   * @private
   * @param {Array<[date, number]>} data
   * @param {string} name
   * @param {string} color
   * @param {number} yAxis
   * @param {string} valueSuffix
   * @memberof MonthlyExecutionChartDataMapper
   */
  private toScatterSerie(data: ChartColumnSerie[], name: string, color: string, yAxis = 1, valueSuffix = "%"): ChartColumnScatterSerie {
    return {
      type: 'scatter',
      name: name,
      color: color,
      marker: {
        radius: 6,
      },
      tooltip: {
        pointFormat: '{point.y}',
        valueSuffix: valueSuffix
      },
      yAxis: yAxis,
      data: data.map(d => [moment(d.date).toDate().getTime(), d.value])
    }
  }

  /**
   * Return a plot line option
   * 
   * @param value 
   * @param color 
   * @param valueSuffix 
   * @returns 
   */
  private toPlotLineOption(value: number, color: string, valueSuffix = "%"): ChartPlotLineOption {
    return {
      id: CHART_COLUMN_XAXIS_PLOTLINE_ID.toString(),
      color: color,
      width: 3,
      value: value,
      label: {
        text: `${value}${valueSuffix}`,
        align: 'right',
        x: 0,
        y: -10
      },
      dashStyle: 'Dash'
    }
  }

  public mapData(data: ExecutionChartDTO, i18n: I18N): MonthlyExecutionChartData {
    return {
      outgoingsAmountsSerie: this.toColumnSerie(data.outgoingsAmounts, i18n.tr('dashboard.expense'), '#98a6ff', CHART_COLUMN_YAXIS_AMOUNTS_ID, 1),
      billedAmountsSerie: this.toColumnSerie(data.billedAmounts, i18n.tr('dashboard.amountBilled'), '#4b52e2', CHART_COLUMN_YAXIS_AMOUNTS_ID, 2),
      progressStatesInitialAmountsSerie: this.toColumnSerie(data.progressStatesInitialAmounts, i18n.tr('dashboard.incomeInitial'), '#482438', CHART_COLUMN_YAXIS_AMOUNTS_ID, 3),
      progressStatesPayOutAmountsSerie: this.toColumnSerie(data.progressStatesPayOutAmounts, i18n.tr('dashboard.incomePayOut'), '#242547', CHART_COLUMN_YAXIS_AMOUNTS_ID, 3),
      realMarginsSerie: this.toScatterSerie(data.realMargins, i18n.tr('dashboard.realMargin'), '#009b47', CHART_COLUMN_YAXIS_PERCENT_ID),
      forecastMarginPlotLineOption: this.toPlotLineOption(data.forecastMargin, '#009b47')
    }
  }
}
//#endregion
