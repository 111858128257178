@import "../css/scss/variables";
.task-component {
  display: flex;
  justify-content: space-between;
  &__desc {
    width: 100%;
    &__header {
      display: flex;
      justify-content: space-between;
      &__left {
      }
      &__right {
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      &__link {
        font-size: 14px;
        line-height: 21px;
        color: $gray-mid;
      }
      &__title {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        height: 28px;
      }
      &__description {
      }
      &__action {
      }
      &__files {
        padding-top: 20px;
        > div {
          display: flex;
          > ui-icon {
            font-size: 20px;
            color: $blue-mid;
          }
          > div.task-component__desc__body__files__info {
            > span {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              cursor: pointer;
            }
            > div > span {
              padding-left: 5px;
              font-weight: 400;
              font-size: 12px;
              color: $gray-mid;
            }
          }
        }
      }
    }
  }
  &__edit-column {
    width: 300px;
    border-left: 1px $gray-lighter solid;

    > div {
      min-height: 90px;
      padding: 20px 10px 20px 50px;
      border-bottom: 1px $gray-lighter solid;
      display: flex;
      justify-content: space-between;
      > div:first-child {
        gap: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .task-component__edit-column__title {
          font-weight: 600;
          font-size: 14px;
        }
        > span > img {
          width: 24px;
          height: 24px;
        }
        > ui-field[no-label].ui-field {
          flex: none;
        }
      }
      > div:last-child > ui-button {
        width: 30px;
        height: 30px;
        > div > a {
          padding: 0;
          > div {
            padding: 0;
          }
        }
      }
    }

    > .task-component__edit-column__sub-tasks {
      > div {
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            text-decoration: underline;
            &:hover {
              color: $blue-mid;
              cursor: pointer;
            }
          }
          > ui-icon {
            color: $gray-light;
          }
        }
        > span + ui-button,
        div + ui-button {
          width: 176px;
          height: 32px;
          > div > a {
            padding: 0;
            > div {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
