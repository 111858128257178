import { CustomLogger, FieldType, LocalityList } from "digiwall-lib";
import { Router } from "aurelia-router";
import { autoinject, useView } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { ColDef } from "ag-grid-community";
import { GridHelperMerlin } from "resources/elements/grid-helper";

@autoinject
@useView("./locality-list.html")
export class ExtendedLocalityList extends LocalityList {
  public buttonLabel: string = this.i18n.tr("common:locality.addLocality");

  constructor(router: Router, logger: CustomLogger, i18n: I18N) {
    super(router, logger, i18n);

  }

  // get canAdd() {
  //   return true;
  // }

  // public get routePrefix(): string {
  //   return "localities/";
  // }

  override getDataGridColumns(): ColDef<any>[] {
    let colDef = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("locality.zipCode"),
        field: "zipCode",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("locality.provincieName"),
        field: "provincieName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("locality.isSubCommune"),
        field: "isSubCommune",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("locality.name") + this.i18n.tr("general.inBracketsFR"),
        field: "name.lang1",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("locality.name") + this.i18n.tr("general.inBracketsNL"),
        field: "name.lang2",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("locality.name") + this.i18n.tr("general.inBracketsEN"),
        field: "name.lang3",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("country.country"),
        field: "country.name._translation",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("locality.communeId"),
        field: "commune.name._translation",
        type: FieldType.String
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return colDef;
  }

}
