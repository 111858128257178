import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';
import { RequestFilterComparaisonPriceOfferLine, IPriceOfferLineGridService, IDataLineApiService, BulkUpdateOperation, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { GridOptions } from "ag-grid-community";
import { Box, GlobalLoaderService } from 'digiwall-lib';
import { I18N } from 'aurelia-i18n';
import { FiltersUtils } from 'resources/utilities/filter-utils';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { RefToDisplay } from '../constants';
import { JSONPatchOperation } from './json-patch-operation';


export abstract class IComparisonService implements IDataLineApiService {

  public supplierIds: Array<number>;
  public requestSupplierOfferId: number;
  public activitiesIds: Array<any>;
  public isFirstTime: boolean = true;
  public defaultColumnDef = null;
  public suppliers = [];

  abstract createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> | Error;
  abstract children(id: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<any[]>;
  abstract filter(id: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<any[]>;
  abstract select(lineId: number, filterModel?: { [key: string]: any; });
  abstract unselect(lineId: number, filterModel?: { [key: string]: any; });
  abstract getMeteringTotalPricesData(versionId: number, activitiesSelected: number[], supplierSelected: number[], requestSupplierOfferSelected: number);
  abstract unSelectPrice(versionId: number, lineId: number): Promise<Array<any>>;
  abstract unSelectPriceOfSupplier(versionId: number): Promise<Array<any>>;
  abstract fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]>;
  abstract createLinkBetweenPOLAndSOL(versionId: number, supplierOfferLineId: number, keepModifiedQuantity: boolean, supplierOffeId: number)
  abstract mapSuppliersColumn(lines: Array<any>);

  abstract getThirdParty(requestSupplierOfferId: number): Promise<Array<ThirdPartyComparison>>;

  abstract patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>>
  abstract bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>>
  abstract duplicateLine?(versionId: number, lineId: number): Promise<Array<number>>
  abstract create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>>
  abstract move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>>
  abstract paste(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>>
  abstract subscribe(callback: () => void)
  abstract delete(versionId: number, lineIds: Array<number>): Promise<Array<number>>
  abstract getLastChapterId(versionId: number): Promise<number>
  abstract computePAR?(versionId: number): Promise<boolean>
  abstract deletePAR?(versionId: number, lineId: number): Promise<boolean>
  abstract versionHasLines?(versionId: number): Promise<boolean>
  abstract getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices>
  abstract createVariantGroup?(versionId: number, lineIds: number[]): Promise<Array<number>>
  abstract deleteVariantGroup?(versionId: number, lineId: number): Promise<Array<number>>
  abstract recomputeLines(versionId: number): Promise<MeteringTotalPrices>
  abstract acceptFromEstimatedPrice(versionId: number, lineId: number, keepModifiedQuantity: boolean): Promise<Array<number>>
}

@autoinject
export class OfferComparisonService implements IComparisonService {
  gridOptions: GridOptions<any>;

  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private filtersUtils: FiltersUtils, private globalLoaderService: GlobalLoaderService) { }

  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.ComparisonController.BulkPatch.format(versionId.toString());

    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ComparisonController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }

  duplicateLine?(versionId: number, lineId: number): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  paste(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  delete(versionId: number, lineIds: Array<number>): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  getLastChapterId(versionId: number): Promise<number> {
    throw new Error('Method not implemented.');
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  versionHasLines?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  deleteVariantGroup?(versionId: number, lineId: number): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }

  public supplierIds: number[];
  public requestSupplierOfferId: number;
  public activitiesIds: any[];
  public isFirstTime: boolean = true;
  public defaultColumnDef: any = null;
  public suppliers: any[] = [];
  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }


  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    // if (this.activitiesIds != null && this.activitiesIds.length > 0) {
    //   await this.filtersUtils.forceFilterValues(filterParams, "activityCodeId", this.activitiesIds, Constants.EnumerationTypes.ActivityCode);
    // } else if (filterParams["activityCodeId"] != null) {
    //   delete filterParams["activityCodeId"];
    // }

    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: refToDisplay
    };

    let requestUri = Constants.Application.ComparisonController.GetAllWithComparisonColumn.format(versionId.toString());
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      let lines = await response.json();
      this.suppliers = [];
      this.mapSuppliersColumn(lines)
      return lines;
    }
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.ComparisonController.GetChildrenWithComparisonColumn.format(versionId.toString(), lineId.toString());
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (query.ok) {
      let lines = await query.json();
      this.mapSuppliersColumn(lines);
      return lines;
    }
  }

  public async createLinkBetweenPOLAndSOL(versionId: number, supplierOfferLineId: number, keepModifiedQuantity: boolean, requestToSupplierId: number) {
    let requestParams = {
      RequestToSupplierId: requestToSupplierId,
      supplierOfferLineId: supplierOfferLineId,
      keepModifiedQuantity: keepModifiedQuantity
    };
    let requestUri = Constants.Application.ComparisonController.CreateLink.format(versionId.toString());
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  async getMeteringTotalPricesData(versionId: number, activitiesSelected: number[], supplierSelected: number[], requestSupplierOfferSelected: number) {
    let requestParams: FooterModel = {
      activitiesSelected: activitiesSelected ?? [],
      supplierSelected: supplierSelected ?? [],
      requestSupplierOfferId: requestSupplierOfferSelected ?? null
    };
    let requestUri = Constants.Application.ComparisonController.ComparisonFooter.format(versionId.toString());
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (query.ok) {
      let footer = await query.json();
      return footer;
    }
  }

  async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay): Promise<any[]> {
    let newIds = ids.filter(x => typeof (x) == 'number');
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: newIds,
      refToDisplay: refToDisplay
    };
    if (this.supplierIds?.length > 0) {
      requestParams.activitiesIds = []
    } else {
      requestParams.supplierIds = [];
    }

    let requestUri = Constants.Application.ComparisonController.ComparisonGetByIds.format(versionId.toString(), displayHidden.toString());
    this.globalLoaderService.allow();
    let result = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      this.mapSuppliersColumn(entities);
      return entities;
    }
    else {
      return null;
    }
  }

  async unSelectPrice(versionId: number, lineId: number): Promise<Array<any>> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: false,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: null,
      refToDisplay: RefToDisplay.MerlinRef
    };
    let requestUri = Constants.Application.ComparisonController.UnSelectPrice.format(versionId.toString(), lineId.toString());
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
    return [];
  }
  async unSelectPriceOfSupplier(versionId: number): Promise<any[]> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: false,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: null,
      refToDisplay: RefToDisplay.MerlinRef
    };
    let requestUri = Constants.Application.ComparisonController.UnSelectPriceOfSupplier.format(versionId.toString());
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
    return [];
  }


  async getThirdParty(requestSupplierOfferId: number): Promise<Array<ThirdPartyComparison>> {
    this.globalLoaderService.allow();
    let response = await this.httpClient.get(Constants.Application.ComparisonController.GetThirdParties.format(requestSupplierOfferId.toString()));
    if (response.ok) {
      return await response.json();
    }
  }


  async acceptFromEstimatedPrice(versionId: number, lineId: number, keepModifiedQuantity: boolean) {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: true,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: RefToDisplay.MerlinRef
    };
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.ComparisonController.AcceptFromEstimatedPrice.format(versionId.toString(), lineId?.toString(), keepModifiedQuantity.toString()), JSON.stringify(requestParams));
    if (query.ok) {
      return await query.json();
    }
  }

  mapSuppliersColumn(lines: Array<any>) {
    let suppliersTreated = false;
    lines.every((line, index) => {
      if (suppliersTreated === true) return false;
      for (let key in line.supplierPrices) {
        if (key == '$id' || key == '$type') continue;
        if (!this.suppliers.some(s => s.key == key)) {
          this.suppliers.push({ key: key, value: line.supplierPrices[key].displayName });
          suppliersTreated = true;
        }
      }
      return true;
    });
  };
}

@autoinject
export class BuyingComparisonService implements IComparisonService {
  gridOptions: GridOptions<any>;

  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, private filtersUtils: FiltersUtils, private globalLoaderService: GlobalLoaderService) { }

  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.ComparisonBuyingController.BulkPatch.format(versionId.toString());

    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return bulkUpdateOperation.map(x => x.lineId);
      }
    }
    return [];
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ComparisonBuyingController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }
  duplicateLine?(versionId: number, lineId: number): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  move(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  paste(versionId: number, lineIds: Array<number>, targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  delete(versionId: number, lineIds: Array<number>): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  getLastChapterId(versionId: number): Promise<number> {
    throw new Error('Method not implemented.');
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  versionHasLines?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  deleteVariantGroup?(versionId: number, lineId: number): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }

  public supplierIds: number[];
  public requestSupplierOfferId: number;
  public activitiesIds: any[];
  public isFirstTime: boolean = true;
  public defaultColumnDef: any = null;
  public suppliers: any[] = [];

  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<Array<number>> {
    throw new Error('Method not implemented.');
  }
  subscribe(callback: () => void) {
    this.callback = callback;
  }
  public async select(lineId: number) {
    return null;
  }
  public async unselect(lineId: number) {
    return null;
  }

  public async filter(projectId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    // if (this.activitiesIds != null && this.activitiesIds.length > 0) {
    //   await this.filtersUtils.forceFilterValues(filterParams, "activityCodeId", this.activitiesIds, Constants.EnumerationTypes.ActivityCode);
    // } else if (filterParams["activityCodeId"] != null) {
    //   delete filterParams["activityCodeId"];
    // }

    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: refToDisplay
    };

    this.globalLoaderService.allow();
    let response = await this.httpClient.post(Constants.Application.ComparisonBuyingController.GetAllWithComparisonColumn.format(projectId.toString()), JSON.stringify(requestParams));
    if (response.ok) {
      let lines = await response.json();
      this.suppliers = [];
      this.mapSuppliersColumn(lines)
      return lines;
    }
  }

  public async children(projectId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: refToDisplay
    };
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.ComparisonBuyingController.GetChildrenWithComparisonColumn.format(projectId.toString(), lineId.toString()), JSON.stringify(requestParams));
    if (query.ok) {
      let lines = await query.json();
      this.mapSuppliersColumn(lines);
      return lines;
    }
  }

  public async createLinkBetweenPOLAndSOL(projectId: number, supplierOfferLineId: number, keepModifiedQuantity: boolean, requestToSupplierId: number) {
    let requestParams = {
      RequestToSupplierId: requestToSupplierId,
      supplierOfferLineId: supplierOfferLineId,
      keepModifiedQuantity: keepModifiedQuantity
    };
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(Constants.Application.ComparisonBuyingController.CreateLinkBetweenPOLAndSOL.format(projectId.toString()), JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  async getMeteringTotalPricesData(projectId: number, activitiesSelected: number[], supplierSelected: number[], requestSupplierOfferSelected: number) {
    let requestParams: FooterModel = {
      activitiesSelected: activitiesSelected ?? [],
      supplierSelected: supplierSelected ?? [],
      requestSupplierOfferId: requestSupplierOfferSelected ?? null
    };
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.ComparisonBuyingController.ComparisonFooter.format(projectId.toString()), JSON.stringify(requestParams));
    if (query.ok) {
      let footer = await query.json();
      return footer;
    }
  }

  async fetch(projectId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay): Promise<any[]> {
    let newIds = ids.filter(x => typeof (x) == 'number');
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: displayHidden,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: newIds,
      refToDisplay: refToDisplay
    };
    if (this.supplierIds?.length > 0) {
      requestParams.activitiesIds = []
    } else {
      requestParams.supplierIds = [];
    }

    this.globalLoaderService.allow();
    let result = await this.httpClient.post(Constants.Application.ComparisonBuyingController.ComparisonGetByIds.format(projectId.toString()), JSON.stringify(requestParams));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      this.mapSuppliersColumn(entities);
      return entities;
    }
    else {
      return null;
    }
  }

  async unSelectPrice(projectId: number, lineId: number): Promise<Array<any>> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: false,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: null,
      refToDisplay: RefToDisplay.MerlinRef
    };
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(Constants.Application.ComparisonBuyingController.UnSelectPrice.format(projectId.toString(), lineId.toString()), JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
    return [];
  }
  async unSelectPriceOfSupplier(projectId: number): Promise<any[]> {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: false,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: null,
      refToDisplay: RefToDisplay.MerlinRef
    };
    this.globalLoaderService.allow();
    let response = await this.httpClient.post(Constants.Application.ComparisonBuyingController.UnSelectPriceOfSupplier.format(projectId.toString()), JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
    return [];
  }

  async getThirdParty(requestSupplierOfferId: number): Promise<Array<ThirdPartyComparison>> {
    this.globalLoaderService.allow();
    let response = await this.httpClient.get(Constants.Application.ComparisonBuyingController.GetThirdParties.format(requestSupplierOfferId.toString()));
    if (response.ok) {
      return await response.json();
    }
  }

  async acceptFromEstimatedPrice(versionId: number, lineId: number, keepModifiedQuantity: boolean) {
    let requestParams: RequestFilterComparaisonPriceOfferLine = {
      filterModel: undefined,
      quickFilter: undefined,
      displayMode: undefined,
      displayHidden: true,
      supplierIds: this.supplierIds,
      requestSupplierOfferId: this.requestSupplierOfferId,
      activitiesIds: this.activitiesIds,
      lineIds: [],
      refToDisplay: RefToDisplay.MerlinRef
    };
    this.globalLoaderService.allow();
    let query = await this.httpClient.post(Constants.Application.ComparisonBuyingController.AcceptFromEstimatedPrice.format(versionId.toString(), lineId?.toString(), keepModifiedQuantity.toString()), JSON.stringify(requestParams));
    if (query.ok) {
      return await query.json();
    }
  }

  mapSuppliersColumn(lines: Array<any>) {
    let suppliersTreated = false;
    lines.every((line, index) => {
      if (suppliersTreated === true) return false;
      for (let key in line.supplierPrices) {
        if (key == '$id' || key == '$type') continue;
        if (!this.suppliers.some(s => s.key == key)) {
          this.suppliers.push({ key: key, value: line.supplierPrices[key].displayName });
          suppliersTreated = true;
        }
      }
      return true;
    });
  };

}

export interface FooterModel {
  activitiesSelected: number[];
  supplierSelected: number[];
  requestSupplierOfferId: number | null;
}

export interface ComparaisonBodyThirdParty {
  workQuotationVersionId: number | null;
  projectId: number | null;
  requestSupplierOfferId: number | null;
}
export interface ThirdPartyComparison {
  id: number;
  name: string;
}
