import { DashboardService, ExecutionForProject } from 'services/dashboard-service';
import { FloatingBoxViewModel } from "digiwall-lib";
import { MonthlyExecutionChartData, MonthlyExecutionChartDataMapper } from 'resources/charts/chart-mappers';
import { I18N } from 'aurelia-i18n';

export class ChartProject extends FloatingBoxViewModel {
  data: ExecutionForProject;
  monthlyExecutionChartConfigDataMapper = new MonthlyExecutionChartDataMapper();
  monthlyExecutionChartData: MonthlyExecutionChartData;

  constructor(private projectId: any, private dashboardService: DashboardService, private i18n: I18N) {
    super('./chart-project.html', 'lg');
  }

  async activate(config: any): Promise<void> {
    await super.activate(config);
    this.data = await this.dashboardService.getProgressStatementForProject(this.projectId);
    this.monthlyExecutionChartData = this.monthlyExecutionChartConfigDataMapper.mapData(this.data.executionChartDTO, this.i18n);
  }

  public async close() {
    await this.dialogController.close(false);
  }


}
