import { ICellRendererParams } from "ag-grid-community";
import { autoinject, Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import * as Constants from '../../../constants';
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { Box, GlobalLoaderService } from "digiwall-lib";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import { AddItems } from "projects/quotes/add-items/add-items";
@autoinject
export class MenuAdd {

  constructor() { }

  public getMenu(params: ICellRendererParams, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string, workQuotationVersionId: string, priceOfferVersionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any, hideDetail: boolean = false, hideAddItem: boolean = false) {
    let i18n = Container.instance.get(I18N);

    let menuItem = {
      group: "0",
      hiddenLabel: true,
      items: [
        {
          label: i18n.tr("versionmetering.detailPost"),
          icon: "digi-add-circle-line",
          disabled: () => {
            let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
            return priceOfferLinesGrid.gridOptions.api.isAnyFilterPresent() || targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data || targetNode.data.quantityTypeId == Constants.MarketType.PM;
          },
          hidden: hideDetail,
          items: [
            {
              label: i18n.tr("versionmetering.detailPostAddItem"),
              disabled: () => {
                return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid)
              },
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, workQuotationVersionId, priceOfferVersionId, getMeteringTotalPrices, _this);
              },
              icon: "digi-database-2-line"
            },
            {
              label: i18n.tr("versionmetering.detailPostAddPost"),
              handler: () => {
                this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, versionId);
              },
              disabled: () => {
                return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid);
              },
              icon: "digi-database-2-line"
            },
            {
              label: i18n.tr("versionmetering.detailPostAddComment"),
              handler: () => {
                this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, versionId);
              },
              disabled: () => {
                return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid);
              },
              icon: "digi-list-check"
            },
          ]
        },
        {
          label: i18n.tr("versionmetering.addPost"),
          icon: "digi-flashlight-line",
          disabled: () => {
            return priceOfferLinesGrid.gridOptions.api.isAnyFilterPresent()
          },
          items: this.getCreateLineMenuItem(parseInt(params.node?.id), Constants.PriceOfferLineCategory.Data, priceOfferLinesGrid, dataLineApiService, versionId)
        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM,
          label: i18n.tr("versionmetering.addItems"),
          icon: "digi-database-2-line",
          disabled: () => {
            return priceOfferLinesGrid.gridOptions.api.isAnyFilterPresent()
          },
          hidden: hideAddItem,
          items: [
            {
              id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_UP,
              label: i18n.tr("versionmetering.onTopOfLine"),
              disabled: () => {
                return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid)
              },
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, dataLineApiService, workQuotationVersionId, priceOfferVersionId, getMeteringTotalPrices, _this);
              },
              icon: "digi-arrow-up-line"
            },
            {
              id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_CHILD,
              label: i18n.tr("versionmetering.childOfLine"),
              disabled: () => {
                let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
                return targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter
              },
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, workQuotationVersionId, priceOfferVersionId, getMeteringTotalPrices, _this);
              },
              icon: "digi-arrow-right-line"
            },
            {
              id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_DOWN,
              label: i18n.tr("versionmetering.belowLine"),
              disabled: () => {
                return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid)
              },
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, dataLineApiService, workQuotationVersionId, priceOfferVersionId, getMeteringTotalPrices, _this);
              },
              icon: "digi-arrow-down-line"
            },

          ]
        },
        {
          label: i18n.tr("versionmetering.addChapter"),
          icon: "digi-list-check",
          disabled: () => {
            return priceOfferLinesGrid.gridOptions.api.isAnyFilterPresent()
          },
          items: this.getCreateLineMenuItem(parseInt(params.node.id), Constants.PriceOfferLineCategory.Chapter, priceOfferLinesGrid, dataLineApiService, versionId)
        },
        {
          label: i18n.tr("versionmetering.addComment"),
          icon: "digi-chat-4-line",
          disabled: () => {
            return priceOfferLinesGrid.gridOptions.api.isAnyFilterPresent()
          },
          items: this.getCreateLineMenuItem(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, priceOfferLinesGrid, dataLineApiService, versionId)
        }
      ]
    };
    return menuItem
  }


  private cannotCreateLine(targetId: number, categoryId: Constants.PriceOfferLineCategory, moveType: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      if (moveType == Constants.PriceOfferLineMoveAction.Into &&

        (((!targetNode.data.isDetailedLine || !targetNode.data.isSubItem) && categoryId == Constants.PriceOfferLineCategory.Chapter) ||

          (targetNode.data.hasChildrenDetailed && categoryId != Constants.PriceOfferLineCategory.Comment) ||

          (targetNode.data.originalMeasurementXlsLineNumber == null && categoryId == Constants.PriceOfferLineCategory.Chapter))) {

        return true;
      }
      if (targetNode.data.isDetailedLine && categoryId != Constants.PriceOfferLineCategory.Comment) {
        return true;
      }
      if (targetNode.data.isSubItem && (categoryId == Constants.PriceOfferLineCategory.Chapter || (moveType == Constants.PriceOfferLineMoveAction.Into && categoryId != Constants.PriceOfferLineCategory.Comment))) {
        return true;
      }
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && categoryId == Constants.PriceOfferLineCategory.Chapter && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    return false;
  }

  private getCreateLineMenuItem(targetId: number, category: Constants.PriceOfferLineCategory, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string) {
    let i18n = Container.instance.get(I18N);
    return [
      {
        label: i18n.tr("versionmetering.onTopOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, dataLineApiService, versionId);
        },
        disabled: () => {
          return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid);
        },
        icon: "digi-arrow-up-line"
      },
      {
        label: i18n.tr("versionmetering.childOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, dataLineApiService, versionId);
        },
        disabled: () => {
          let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
          return targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter
        },
        icon: "digi-arrow-right-line"
      },
      {
        label: i18n.tr("versionmetering.belowLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, dataLineApiService, versionId);
        },
        disabled: () => {
          return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid);
        },
        icon: "digi-arrow-down-line"
      },

    ]
  }

  private async createLine(targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, versionId: string) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }
    let rowIndex = targetNode.rowIndex;

    targetNode.data.isSelected = false;

    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let globalLoaderService = Container.instance.get(GlobalLoaderService);

    globalLoaderService.allow();

    let ids = await dataLineApiService.create(parseInt(versionId), targetId, categoryId, action);
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null && action != Constants.PriceOfferLineMoveAction.Into);

    switch (action) {
      case Constants.PriceOfferLineMoveAction.Into:
        targetNode.setExpanded(true);
        setTimeout(() => {
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: (targetNode.childStore as any).allRowNodes[(targetNode.childStore as any).allRowNodes.length - 1].rowIndex, colKey: "lineDescription" });
        }, 250)
        break;
      case Constants.PriceOfferLineMoveAction.Up:
        setTimeout(() => {
          let newLine = priceOfferLinesGrid.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order)[0];
          let newNode = priceOfferLinesGrid.gridOptions.api.getRowNode(newLine.id.toString());
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "lineDescription" });
        }, 750)
        break;
      case Constants.PriceOfferLineMoveAction.Down:
        setTimeout(() => {
          let newLine = priceOfferLinesGrid.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order + 1)[0];
          let newNode = priceOfferLinesGrid.gridOptions.api.getRowNode(newLine.id.toString());
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "lineDescription" });
        }, 750)
        break;
    }
  }

  private async addItems(targetId: number, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, dataLineApiService: IDataLineApiService, workQuotationVersionId: string, priceOfferVersionId: string, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }
    let box = Container.instance.get(Box);

    await box.showCustomDialog(AddItems, targetId, null, {
      model: {
        action: action,
        workQuotationVersionId: workQuotationVersionId,
        priceOfferVersionId: priceOfferVersionId,
        api: dataLineApiService,
        parent: action == Constants.PriceOfferLineMoveAction.Into && targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data ? targetNode.data : null
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        priceOfferLinesGrid.refreshServerSideRows(result.output, targetNode.data.parentId == null && action != Constants.PriceOfferLineMoveAction.Into);
        if (action == Constants.PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
        getMeteringTotalPrices(priceOfferVersionId != null ? priceOfferVersionId : workQuotationVersionId, dataLineApiService, _this);
      }
    });
  }
}
