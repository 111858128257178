export default {
  debug: true,
  testing: false,
  name: 'test',
  remoteApiUrl: 'https://merlin-test.api.merlinsoftware.be',
  auth: {
    clientId: 'Bjh7D2sEqekcVV8w3tKKQtZEZoR4qa8d',
    domain: 'merlin-software.eu.auth0.com',
    audience: 'http://localhost:4500',
    callback_uri: 'https://merlin-test.merlinsoftware.be/callback',
    logout_uri: 'https://merlin-test.merlinsoftware.be'
  },
  googleApiKey: "AIzaSyA0wGw9heaw3i3JMyBO3vMPK3J3FVW-tYI",
  lancelotInviteEnabled: true
};
export const version: String = "0.13.0-beta-0032";