export class SummaryLineCoefficient {
  id: number;
  supplierOfferId: number | null;
  buildingCostsId: number | null;
  ownWorkId: number | null;

  activityId: number | null;
  name: string;
  subConstractorName: string;
  activityName: string;
  benefitMarginCoefficient: number | null;
  benefitMarginAmount: number | null;
  overheadMarginCoefficient: number | null;
  overheadMarginAmount: number | null;
  riskMarginCoefficient: number | null;
  riskMarginAmount: number | null;
  marginAmout: number | null;

  weight: number | null;
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;

  ids: number[];
}


export class POVSummary {
  ownWork: OwnWork;
  subContractor: SubContractor;
  buildingCostsLine: BuildingCostsLine;
  noCategoryLine: SummaryLineCoefficient;
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class OwnWork {
  workforce: SummaryLineCoefficient;
  material: SummaryLineCoefficient;
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class SubContractor {
  subConstractorSupplierOffer: SubConstractorSupplierOffer;
  subConstactorEstim: SubConstactorEstim;
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class SubConstractorSupplierOffer {
  summaryLineCoefficients: SummaryLineCoefficient[];
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class SubConstactorEstim {
  summaryLineCoefficients: SummaryLineCoefficient[];
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export class BuildingCostsLine {
  summaryLineCoefficients: SummaryLineCoefficient[];
  totalBuyingPrice: number | null;
  totalSellingPrice: number | null;
}

export interface UpdateCoefficientDto {
  coefficient: number | null;
  ids: number[];
}
