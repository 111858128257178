import { ICellRendererParams } from "ag-grid-community";
import { autoinject, Container } from "aurelia-framework";
import * as Constants from '../../../constants';
import { I18N } from "aurelia-i18n";
import { PriceOfferLineCategory } from "../../../constants";
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, IMenuItems } from "digiwall-lib";
import { DocumentTypeDiscriminator } from '../../../request-supplier-offers/request-supplier-offer-detail';
import { PriceRepartitionService } from "services/price-repartition-service";
import { PriceRepartition } from "projects/quotes/price-repartition/price-repartition";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";

@autoinject
export class MenuPriceRepartition {
  constructor() { }
  public getMenuItems(params: ICellRendererParams, priceRepartitionService: PriceRepartitionService, documentTypeDiscriminator: DocumentTypeDiscriminator, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems[] {
    let i18n = Container.instance.get(I18N);
    if (!params.node.data.hasPriceRepartition) {
      return [{
        id: Constants.CommonMeterginDataLineMenuItems.PRICE_REPARTITION_ADD,
        label: i18n.tr("versionmetering.addPriceRepartition"),
        icon: "digi-add-circle-line",
        handler: async () => {
          await this.addPriceRepartition(params.node.data.id, documentTypeDiscriminator, priceRepartitionService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this)
        },
        disabled: () => true /* params.node.data.priceOfferLineCategoryId == PriceOfferLineCategory.Comment || (params.node.data.priceOfferLineCategoryId == PriceOfferLineCategory.Data && params.node.data.originalMeasurementXlsLineNumber != null && params.node.data.quantityTypeId != Constants.MarketType.PM) || params.node.data.isSubItem*/
      }]
    }
    return [
      {
        id: Constants.CommonMeterginDataLineMenuItems.PRICE_REPARTITION_EDIT,
        label: i18n.tr("versionmetering.editPriceRepartition"),
        icon: "digi-edit",
        disabled: () => true /*!params.node.data.hasPriceRepartition*/,
        handler: async () => {
          await this.editPriceRepartition(params.node.data.id, documentTypeDiscriminator, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this)
        }
      },
      {
        id: Constants.CommonMeterginDataLineMenuItems.PRICE_REPARTITION_DELETE,
        label: i18n.tr("versionmetering.deletePriceRepartition"),
        icon: "digi-trash",
        disabled: () => !params.node.data.hasPriceRepartition,
        handler: async () => {
          await this.deletePriceRepartition(params.node.data, documentTypeDiscriminator, priceRepartitionService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this);
        }
      }
    ];
  }


  //#region price repartition
  private async addPriceRepartition(priceOfferLineId: string, documentTypeDiscriminator: DocumentTypeDiscriminator, priceRepartitionService: PriceRepartitionService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    priceRepartitionService.type = documentTypeDiscriminator;
    let box = Container.instance.get(Box);
    let i18n = Container.instance.get(I18N);

    let hasChildren = await priceRepartitionService.hasChildren(null, priceOfferLineId);
    if (!hasChildren) {
      let canAdd = await priceRepartitionService.canAddPriceRepartition(null, priceOfferLineId);
      if (canAdd) {
        box.showCustomDialog(PriceRepartition,
          parseInt(priceOfferLineId),
          null,
          {
            model: {
              type: documentTypeDiscriminator,
              fkId: versionId,
              refToDisplay: priceOfferLinesGrid.listTreeData.refToDisplay
            },
            size: 'md'
          })
          .whenClosed(async result => {
            if (!result.wasCancelled) {
              getMeteringTotalPrices(versionId, priceOfferLinesGrid.api, _this);
              priceOfferLinesGrid.refreshServerSideRows(null, true);
            }
          });
      }
      else {
        box.showWarning(i18n.tr('versionmetering.notCanAddPriceRepartition'), i18n.tr('general.warningTitle'));
      }
    } else {
      box.showWarning(i18n.tr('versionmetering.notCanAddPriceRepartitionHasChildren'), i18n.tr('general.warningTitle'));
    }

  }

  private editPriceRepartition(priceOfferLineId: string, documentTypeDiscriminator: DocumentTypeDiscriminator, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    let box = Container.instance.get(Box);
    box.showCustomDialog(PriceRepartition, parseInt(priceOfferLineId), null, {
      model: {
        type: documentTypeDiscriminator,
        fkId: versionId,
        refToDisplay: priceOfferLinesGrid.listTreeData.refToDisplay
      },
      size: 'md'
    }).whenClosed((result) => {
      if (!result.wasCancelled) {
        getMeteringTotalPrices(versionId, priceOfferLinesGrid.api, _this);
        priceOfferLinesGrid.refreshServerSideRows(null, true);
      }
    })
  }

  private async deletePriceRepartition(priceOfferLine, documentTypeDiscriminator: DocumentTypeDiscriminator, priceRepartitionService: PriceRepartitionService, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any) {
    if (!(typeof priceRepartitionService.deletePAR == "function" || typeof priceRepartitionService.computePAR == "function")) {
      console.warn('API computePAR/deletePAR not implemented !');
      return;
    }
    let box = Container.instance.get(Box);
    let i18n = Container.instance.get(I18N);

    let buttonDelete: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("general.remove", { ns: "common" }),
      title: i18n.tr("general.remove", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok();
      }
    };
    let buttonCancel: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("general.cancel", { ns: "common" }),
      title: i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.cancel();
      }
    };

    priceRepartitionService.type = documentTypeDiscriminator;
    await box.showQuestion(i18n.tr('versionmetering.removePriceRepartitionQuestion'), i18n.tr('menu.question'), [buttonCancel, buttonDelete]).whenClosed(
      async (result) => {
        if (!result.wasCancelled) {

          let deleted = await priceRepartitionService.deletePAR(parseInt(versionId), priceOfferLine.id);
          if (deleted) {
            let computed = await priceRepartitionService.computePAR(parseInt(versionId));
            if (computed) {
              getMeteringTotalPrices(versionId, priceOfferLinesGrid.api, _this);
              priceOfferLinesGrid.refreshServerSideRows(null, true);
            }
          }

        }
      }
    )
  }

  //#endregion
}
