import { ColumnVisible } from "module-list-tree-data/module-list-tree-data";

export class PriceOfferLineGridHelper {
  public static getBaseColumn(): Array<ColumnVisible> {
    return [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineDescriptionItem", visibility: false },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: false },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: true },
      { colId: "diffIndirectCostFromPAR", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "diffMarginPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "indirectCostFromPAR", visibility: false },
      // { colId: "marginCoefficient", visibility: true },
      //#region coeff
      { colId: "overheadMarginCoefficient", visibility: false },
      { colId: "overheadMarginAmount", visibility: false },
      { colId: "benefitMarginCoefficient", visibility: false },
      { colId: "benefitMarginAmount", visibility: false },
      { colId: "riskMarginCoefficient", visibility: false },
      { colId: "riskMarginAmount", visibility: false },

      //#endregion
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: true },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: false },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "buyingClientValidationStatusId", visibility: false },
      { colId: "buyingClientValidationDeadlineDate", visibility: false },
      { colId: "buyingClientValidationDate", visibility: false },
      { colId: "deliveryDeadlineDate", visibility: false },
      { colId: "unitQuantityDetailItemComposed", visibility: false },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: false },
      { colId: "quantityTypeSupplierTypeId", visibility: false },
      { colId: "totalQuantityDone", visibility: false },
      { colId: "totalQuantityPercentageDone", visibility: false },
      { colId: "isFinalised", visibility: false },
      { colId: "deltaQuantityDone", visibility: false },
      { colId: "deltaQuantityDonePercentage", visibility: false },
      { colId: "absoluteQuantityDone", visibility: false },
      { colId: "absoluteQuantityDonePercentage", visibility: false },
      { colId: "clientAdditionalWorkNum", visibility: false },
      { colId: "clientAdditionalWorkStatusId", visibility: false },
      { colId: "contractNum", visibility: false },
      { colId: "estimatedQuantity", visibility: false },
      { colId: "estimatedBuyingUnitPrice", visibility: false },
      { colId: "estimatedTotalBuyingPrice", visibility: false },
      { colId: "estimatedComment", visibility: false },
      { colId: "buildingCostsId", visibility: true },
    ]
  }

}
