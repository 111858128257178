import { Subscription } from 'aurelia-event-aggregator';
import { bindable, autoinject, customElement, BindingEngine } from 'aurelia-framework';
import * as Highcharts from 'highcharts';
import { ChartConfig } from '../chart-config';
import { I18N } from 'aurelia-i18n';

export const CHART_COLUMN_YAXIS_AMOUNTS_ID = 0;
export const CHART_COLUMN_YAXIS_PERCENT_ID = 1;
export const CHART_COLUMN_XAXIS_PLOTLINE_ID = 1;

@autoinject
@customElement('chart-column')
export class ChartColumn {

  @bindable()
  private series: Array<Highcharts.SeriesOptionsType>;
  // public set series(value: Array<Highcharts.SeriesOptionsType>) {
  //   this._series = value;
  //   this.setChartSeries();
  // }
  // public get series(): Array<Highcharts.SeriesOptionsType> {
  //   return this._series;
  // }

  @bindable()
  private plotLine: Highcharts.YAxisPlotLinesOptions;
  // public set plotline(value: Highcharts.YAxisPlotLinesOptions) {
  //   this._plotLine = value;
  //   this.setChartPlotLine();
  // }
  // public get plotLine(): Highcharts.YAxisPlotLinesOptions {
  //   return this._plotLine;
  // }

  @bindable()
  private zooming = false;

  @bindable
  private showLoading = false;

  protected container: HTMLDivElement;
  private options: Highcharts.Options;
  private chart: Highcharts.Chart;
  private chartConfig: ChartConfig;
  private subscriptions: Array<Subscription> = [];
  constructor(private i18n: I18N, bindingEngine: BindingEngine) {
    this.chartConfig = new ChartConfig(this.i18n);
    this.subscriptions = [
      bindingEngine.propertyObserver(this, 'series').subscribe(series => this.setChartSeries()),
      bindingEngine.propertyObserver(this, 'plotLine').subscribe(series => this.setChartPlotLine())
    ];
  }

  detached() {
    this.subscriptions.forEach(s => s.dispose());
  }

  attached() {
    this.setChartOptions();
    this.chart = Highcharts.chart(this.options);

    if (this.showLoading) {
      this.chart.showLoading();
    }

    if (this.plotLine != null) {
      this.setChartPlotLine();
    }
    if (Array.isArray(this.series)) {
      this.setChartSeries();
    }
  }

  private setChartPlotLine() {
    if (this.chart == null) {
      return;
    }

    let axis = this.chart.yAxis[CHART_COLUMN_YAXIS_PERCENT_ID];
    if (axis != null) {
      axis.removePlotLine(CHART_COLUMN_XAXIS_PLOTLINE_ID.toString());

      if (this.plotLine != null) {
        axis.addPlotLine(this.plotLine);
      }
    }

    this.chart.redraw();
  }

  private setChartSeries() {
    if (this.chart == null) {
      return;
    }

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove();
    }

    if (Array.isArray(this.series)) {
      for (let s of this.series) {
        this.chart.addSeries(s, false);
      }
    }

    this.chart.hideLoading();
    this.chart.redraw();
  }

  private setChartOptions(): void {
    // Set I18N
    Highcharts.setOptions({ lang: this.chartConfig.getLangOptions() });

    this.options = {
      credits: { enabled: false },
      chart: Object.assign({
        type: 'column',
        renderTo: this.container,
        zooming: {
          type: this.zooming ? 'x' : null
        }
      } as Highcharts.ChartOptions, this.chartConfig.getChartOptions()),
      legend: { enabled: true },
      title: {
        style: {
          display: "none"
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      xAxis: {
        type: 'datetime', // Définit l'axe X comme un axe temporel
        labels: {
          format: '{value:%b %Y}' // Affiche les mois en format abrégé (Jan, Feb, etc.)
        },
      },
      yAxis: [
        {
          id: CHART_COLUMN_YAXIS_AMOUNTS_ID.toString(),
          min: 0,
          title: {
            text: null
          },
          // labels: {
          //   format: '{value} €',
          // }
        },
        {
          id: CHART_COLUMN_YAXIS_PERCENT_ID.toString(),
          min: 0,
          max: 100,
          opposite: true,
          title: {
            text: null
          },
          labels: {
            format: '{null}'//'{value} %',
          },
          type: 'linear',
          // tickAmount: 5,
          // plotLines: [this.plotLine],
        },
      ],
      tooltip: {
        shared: true,
      },
      series: [
        {
          name: '',
          id: '1',
          type: 'column',
          showInLegend: false,
          selected: false,
          data: [[Date.now(), null]]
        }
      ]
    }
  }
}
